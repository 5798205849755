.help__avator {
  position:fixed;
  bottom:20px;
  padding-right:20px;
  z-index:900;
  max-width:320px;
  right:0;
  @include transform(translateX(100%));
  @include transition(all, .5s);
  
  &.is-active-static,
  &.is-active {
    @include transform(translateX(0));
  }
  
  @extend .clearfix;
  
  .avator__text {
    border-radius: 6px;	
    background-color: $color-white;	
    padding:25px 36px;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.1);
    position:relative;
    margin-bottom:25px;
    display:none;
    
    &.is-active {
      &.is-animated {
      animation-name: jackInTheBox;
      animation-duration: 1s;
      animation-fill-mode: both;
      display:block;
      }
    }
    
    &:after {
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid $color-white;
      position:absolute;
      right:37px;
      bottom:-13px;
      content:"";

      @include breakpoint(small down){
        right:27px;
      }
      
    }
    
    p {
      margin-bottom:17px;
    }
   
    a {
      text-decoration: underline;
      font-weight:600;
      color:$brand-primary;
      
      &:focus,
      &:hover {
        text-decoration: none;  
      }
      
    }
    
  }
  
  .avator__image {
    float:right;
    cursor: pointer;
    img {
      @include border-radius(100%);
      width:100px;
      height:100px;
      overflow:hidden;
      box-shadow: 0 2px 20px 0 rgba(0,0,0,0.15);
      
      @include breakpoint(small down){
        width:80px;
        height:80px;
      }
      
    }

  }
  
}