// Typography
// -----------------

h1, .h1 {
  font-family: $font-header;
  font-size: rem-calc(54px);
  font-weight:700;
  line-height: rem-calc(58px);

  @include breakpoint(medium down){
    font-size: rem-calc(36px);
    font-weight:bold;
    line-height: rem-calc(42px);
  }
  
  @include breakpoint(small down){
    font-size: rem-calc(26px);
    font-weight:bold;
    line-height: rem-calc(32px);
  }
  
  margin-bottom:rem-calc(20px);

  .page--landing-400 & {
    margin-bottom:rem-calc(5px);
  }
  
}

h2, .h2 {
  font-family: $font-header;
  font-size: rem-calc(38px);
  line-height: rem-calc(47px);
  font-weight:700;
  margin-bottom:22px;

  &.label {
    font-size: rem-calc(38px);
    line-height: rem-calc(47px);
    margin-bottom:20px;
  }
  
  &.font-italic {
    margin-bottom:40px;
  }
  
}

h3, .h3 {
  font-family: $font-header;
  font-size: rem-calc(20px);
  line-height: rem-calc(26px);
  font-weight:700;
  margin-bottom:10px;
  
  &.label {
    font-size: rem-calc(20px);
    line-height: rem-calc(26px);
    margin-bottom:20px;
  }
  
}

h5, .h5 {
  font-weight:bold;
  font-size:1em;
  margin-bottom:20px;
}

.font-italic {
  font-style:italic;
  font-weight:500;
  color:$brand-primary;
}