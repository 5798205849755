
// Product item
// -----------------

.product__item {

  width:100%;
  display:inline-block;
  margin-bottom:50px;

  &:last-child(){
    margin-bottom:0;
  }

  @include breakpoint(landscapebr up){
    @include vertical-align(img);
  }
  

  @include breakpoint(medium up){
    max-width:340px;
  }

  .product__image {
    text-align:center;
    margin-bottom:50px;
    position:relative;
    
    
    .logo {
      position:absolute;
      right:0;
      bottom:10px;
      top:auto;
      transform: none;
    }
    
    .page--landing-400 & {
      @include breakpoint(landscapebr up) {
        margin-top: 50px;
      }
    }

  }
  .product__description {

    text-align:left;
    position:relative;
    padding-bottom:50px;


    h3, .h3 {
      font-size: rem-calc(32px);
      line-height: rem-calc(38px);
      font-weight:600;
      b, strong {
        font-weight:700;
      }
    }


    p {
      font-size: rem-calc(16px);
      line-height: rem-calc(26px);
      margin-bottom:1em;
    }

    ul, ol {
      font-size: rem-calc(18px);
      line-height: rem-calc(30px);
    }

    .text__block {
      padding-bottom:20px;
      color:$brand-secundary;
      @include breakpoint(large){
        padding-bottom:70px;

        .page--landing-400 & {
          padding-bottom:40px;
        }
        
      }
    }

    .product__footer {
      text-align:center;
      position:absolute;
      bottom:0;
      left:0;
      right:0;
      white-space: nowrap;
      .button,
      .link {
        margin-bottom:0;
      }
      
      &.text-left {
        text-align:left;
      }

    }

  }


}
