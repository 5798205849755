// Charts
// -----------------


@keyframes chart10 {
  from {
    stroke-dashoffset: 366;
  }

  to {
    stroke-dashoffset: percenttopx(10, 366);
  }
}

@keyframes chart75 {
  from {
    stroke-dashoffset: 366;
  }

  to {
    stroke-dashoffset: percenttopx(75, 366);
  }
}


@keyframes chart80 {
  from {
    stroke-dashoffset: 366;
  }

  to {
    stroke-dashoffset: percenttopx(80, 366);
  }
}



.chart {
  position: relative;
  display: inline-block;
  color: #999;
  font-size: 20px;
  text-align: center;

  .outer {
    fill: transparent;
    stroke: $brand-primary;
    stroke-width: 10;
    stroke-dasharray: 366;
    transition: stroke-dashoffset 1s;
    animation-play-state: running;
  }

  figcaption {
    width: 126px;
    height: 126px;
    border: 10px solid $color-grey-light;
    @include border-radius(100%);
    display:block;
    position:relative;

    .caption {
      position:absolute;
      top:50%;
      left:50%;
      width:100%;
      text-align:center;
      transform:translateX(-50%) translateY(-50%);

      .counter {
        color:$brand-primary;
        font-size: rem-calc(32px);
        line-height: rem-calc(16px);
        font-weight:700;
      }
      .title {
        display:block;
        font-size: rem-calc(13px);
        line-height: rem-calc(13px);
        color:$brand-text-color;
      }
    }
  }

  &[data-percent='10'] {
    .outer {
      stroke-dashoffset: percenttopx(10, 366);
      @include opacity(0);
    }
    &.is-active {
      .outer {
        @include opacity(1);
        animation: chart10 2s;
      }
    }
  }

  &[data-percent='75'] {
    .outer {
      stroke-dashoffset: percenttopx(75, 366);
      @include opacity(0);
    }
    &.is-active {
      .outer {
        animation: chart75 2s;
        @include opacity(1);
      }
    }

  }

  &[data-percent='80'] {
    .outer {
      stroke-dashoffset: percenttopx(80, 366);
      @include opacity(0);
    }
    &.is-active {
      .outer {
        animation: chart80 2s;
        @include opacity(1);
      }
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover .outer {
    stroke-dashoffset: percenttopx(0, 366) !important;
    animation-play-state: paused;
  }

}