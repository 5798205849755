@import "_vendors/font-awesome/variables";

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?90eas6');
  src:  url('../fonts/icomoon.eot?90eas6#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?90eas6') format('truetype'),
  url('../fonts/icomoon.woff?90eas6') format('woff'),
  url('../fonts/icomoon.svg?90eas6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icomoon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin fontawesome() {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  @include icomoon();
}

.icon-arrow-right,
.icon-help,
.icon-refresh,
.icon-triangle,
.icon-quote {
  &:before {
    @include icomoon();
  }
}

.icon-quote:before {
  content: "\e901";
  color: #4a556b;
}
.icon-help:before {
  content: "\e902";
  color: #ff6200;
}
.icon-refresh:before {
  content: "\e903";
  color: #4a556b;
}
.icon-triangle:before {
  content: "\e900";
  color: #fff;
}

.icon-arrow-right:before {
  content: "\e904";
  color: #fff;
}

.icon-easy-to-use:before {
  content: "\e905";
  color: #994d9d;
}
.icon-experience:before {
  content: "\e906";
  color: #994d9d;
}
.icon-expertise:before {
  content: "\e907";
  color: #994d9d;
}
.icon-plus-icoon:before {
  content: "\e908";
  color: #994d9d;
}


.icon-expertise {
  height: 105px;	
  width: 149px;
  display:inline-block;
  margin-bottom:10px;
  @include icomoon();
  font-size:105px;
  content: "\e907";
  color: $brand-primary;
}

.icon-easytouse {
  height: 90px;
  width: 149px;
  display:inline-block;
  margin-bottom:10px;

  @include icomoon();
  font-size:90px;
  color: $brand-primary;

  &:before {
    content: "\e905";
  }
  
  
}

.icon-readbook {
  height: 105px;
  width: 149px;
  display:inline-block;
  margin-bottom:10px;
  @include icomoon();
  font-size:105px;
  color: #994d9d;
  
  &:before {
    content: "\e906";
  }
  
}
