@charset "UTF-8";

/* -------------------------------------------------------------
 *  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
 *  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets
 *
 *  Mixins available (usage: @include border-radius(10px);:
 *    -   background-gradient     - arguments: Start Color: #3C3C3C, End Color: #999999
 *    -   background-size         - arguments: Width: 100%, Height: 100%
 *    -   border-radius           - arguments: Radius: 5px
 *    -   border-radius-separate  - arguments: Top Left: 5px, Top right: 5px, Bottom Left: 5px, Bottom Right: 5px ( @include border-radius-separate(150px, 0, 150px, 0) )
 *    -   box                     - arguments: Orientation: horizontal, Pack: center, Align: center
 *    -   box-rgba                - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
 *    -   box-shadow              - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
 *    -   box-sizing              - arguments: Type: border-box
 *    -   columns                 - arguments: Count: 3, Gap: 10
 *    -   double-borders          - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
 *    -   flex                    - arguments: Value: 1
 *    -   flip                    - arguments: ScaleX: -1
 *    -   font-face               - arguments: Font Family: myFont, Eot File Src: myFont.eot, Woff File Src: myFont.woff, Ttf File Src: myFont.ttf
 *    -   opacity                 - arguments: Opacity: 0.5
 *    -   outline radius          - arguments: Radius: 5px
 *    -   resize                  - arguments: Direction: both
 *    -   rotate                  - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
 *    CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
 *    -   text-shadow             - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
 *    -   transform               - arguments: Parameters: null
 *    -   transition              - arguments: What: all, Length: 1s, Easing: ease-in-out
 *    -   transition-multiple     - arguments: Parameters: null ( @incl...(transform 0.2s ease-in-out, opacity 0.2s ease-in-out);)
 *    -   triple-borders          - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0
 *    -   vertical-align          - arguments: child selector; Use on parent with vertical aligned object as parameter
 *    -   center-align            - arguments: child selector; Use on parent with vertical aligned object as parameter
 *    -   placeholder             - arguments: color and fontstyle for placeholder
 *    -   animation               - arguments: Parameters: null    ( @include animation( come-in 0.8s ease forwards); )
 *    -   keyframes               - arguments: Animation name   ( @include keyframes(come-in){...} )
 *    -   burger                  - arguments: Width line (px), height line (px), space between lines (px), color (#) for animation call @include burger-to-cross; on hover
 *
 *-------------------------------------------------------------*/



/* BACKGROUND GRADIENT */
@mixin background-gradient($startColor: #3C3C3C, $endColor: #999999){
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image:    -moz-linear-gradient(top, $startColor, $endColor);
  background-image:     -ms-linear-gradient(top, $startColor, $endColor);
  background-image:      -o-linear-gradient(top, $startColor, $endColor);
  background-image:         linear-gradient(top, $startColor, $endColor);
  filter:            progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

/* BACKGROUND SIZE */
@mixin background-size($width: 100%, $height: 100%){
  -moz-background-size: $width $height;
  -webkit-background-size: $width $height;
  background-size: $width $height;
}


/* BORDER RADIUS */
@mixin border-radius($radius: 5px){
  -moz-border-radius:    $radius;
  -webkit-border-radius: $radius;
  border-radius:         $radius;
}

@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px){
  -webkit-border-top-left-radius:     $topLeftRadius;
  -webkit-border-top-right-radius:    $topRightRadius;
  -webkit-border-bottom-right-radius: $bottomRightRadius;
  -webkit-border-bottom-left-radius:  $bottomLeftRadius;

  -moz-border-radius-topleft:     $topLeftRadius;
  -moz-border-radius-topright:    $topRightRadius;
  -moz-border-radius-bottomright: $bottomRightRadius;
  -moz-border-radius-bottomleft:  $bottomLeftRadius;

  border-top-left-radius:     $topLeftRadius;
  border-top-right-radius:    $topRightRadius;
  border-bottom-right-radius: $bottomRightRadius;
  border-bottom-left-radius:  $bottomLeftRadius;
}

/* BOX */
@mixin box($orient: horizontal, $pack: center, $align: center){
  display: -webkit-box;
  display: -moz-box;
  display: box;

  -webkit-box-orient: $orient;
  -moz-box-orient:    $orient;
  box-orient:         $orient;

  -webkit-box-pack: $pack;
  -moz-box-pack:    $pack;
  box-pack:         $pack;

  -webkit-box-align: $align;
  -moz-box-align:    $align;
  box-align:         $align;
}

/* BOX RGBA */
@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3C3C3C){
  background-color: transparent;
  background-color: rgba($r, $g, $b, $opacity);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}',endColorstr='#{$color}');
  zoom:   1;
}


/* BOX SHADOW */
@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $spread: 0px, $color: rgba(0,0,0,.4), $inset:""){
  @if $inset != ""{
    -webkit-box-shadow: $inset $x $y $blur $spread $color;
    -moz-box-shadow:    $inset $x $y $blur $spread $color;
    box-shadow:         $inset $x $y $blur $spread $color;
  }@else{
    -webkit-box-shadow: $x $y $blur $spread $color;
    -moz-box-shadow:    $x $y $blur $spread $color;
    box-shadow:         $x $y $blur $spread $color;
  }
}



/* BOX SIZING */
@mixin box-sizing($type: border-box){
  -webkit-box-sizing:   $type;
  -moz-box-sizing:      $type;
  box-sizing:           $type;
}

/* COLUMNS */
@mixin columns($count: 3, $gap: 10){
  -webkit-column-count: $count;
  -moz-column-count:    $count;
  column-count:         $count;

  -webkit-column-gap:   $gap;
  -moz-column-gap:      $gap;
  column-gap:           $gap;
}

/* DOUBLE BORDERS */
@mixin double-borders($colorOne: #3C3C3C, $colorTwo: #999999, $radius: 0){
  border: 1px solid $colorOne;

  -webkit-box-shadow: 0 0 0 1px $colorTwo;
  -moz-box-shadow:    0 0 0 1px $colorTwo;
  box-shadow:         0 0 0 1px $colorTwo;

  @include border-radius( $radius );
}

/* FLEX */
@mixin flex($value: 1){
  -webkit-box-flex: $value;
  -moz-box-flex:    $value;
  box-flex:         $value;
}

/* FLIP */
@mixin flip($scaleX: -1){
  -moz-transform:    scaleX($scaleX);
  -o-transform:      scaleX($scaleX);
  -webkit-transform: scaleX($scaleX);
  transform:         scaleX($scaleX);
  filter:            FlipH;
  -ms-filter:        "FlipH";
}

/* FONT FACE */
@mixin font-face($fontFamily: myFont, $eotFileSrc: 'myFont.eot', $woffFileSrc: 'myFont.woff', $ttfFileSrc: 'myFont.ttf'){
  font-family: $fontFamily;
  src: url($eotFileSrc)  format('eot'), url($woffFileSrc) format('woff'), url($ttfFileSrc)  format('truetype');
}

/* OPACITY */
@mixin opacity($opacity: 0.5){
  filter:         alpha(opacity=($opacity * 100));
  -ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + ($opacity * 100) + ")";
  -moz-opacity:   $opacity;
  -khtml-opacity: $opacity;
  opacity:        $opacity;
}

/* OUTLINE RADIUS */
@mixin outline-radius($radius: 5px){
  -webkit-outline-radius: $radius;
  -moz-outline-radius:    $radius;
  outline-radius:         $radius;
}

/* RESIZE */
@mixin resize($direction: both){
  -webkit-resize: $direction;
  -moz-resize:    $direction;
  resize:         $direction;
}

/* ROTATE*/
@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0){
  -moz-transform: rotate($deg + deg);
  -o-transform: rotate($deg + deg);
  -webkit-transform: rotate($deg + deg);
  -ms-transform: rotate($deg + deg);
  transform: rotate($deg + deg);
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
  zoom: 1;
}

/* TEXT SHADOW */
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)){
  text-shadow: $x $y $blur $color;
}

/* TRANSFORM  */
@mixin transform($params){
  -webkit-transform: $params;
  -moz-transform:    $params;
  transform:         $params;
}

/* TRANSITION */
@mixin transition($what: all, $length: 1s, $easing: ease-in-out){
  -moz-transition:    $what $length $easing;
  -o-transition:      $what $length $easing;
  -webkit-transition: $what $length $easing;
  -ms-transition:     $what $length $easing;
  transition:         $what $length $easing;
}
@mixin transition-multiple($params...){
  -moz-transition:    $params;
  -o-transition:      $params;
  -webkit-transition: $params;
  -ms-transition:     $params;
  transition:         $params;
}

/* TRIPLE BORDERS */
@mixin triple-borders($colorOne: #3C3C3C, $colorTwo: #999999, $colorThree: #000000, $radius: 0){
  border: 1px solid $colorOne;

  @include border-radius($radius);

  -webkit-box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
  -moz-box-shadow:    0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
  box-shadow:         0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
}

/* VERTICAL ALIGN MIDDLE */
/* use this mixin on parent child selector as parameter */
@mixin vertical-align($child) {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;

  #{$child} {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

/* center ALIGN MIDDLE */
/* use this mixin on parent child selector as parameter */
@mixin center-align($child) {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;

  #{$child} {
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}

/* ANIMATION */
@mixin animation($params){
  -moz-animation:    $params;
  -o-animation:      $params;
  -webkit-animation: $params;
  -ms-animation:     $params;
  animation:         $params;
}

/* KEYFRAMES */
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

/* pxtopercent */
@function pxtopercent($target, $parent: 16) {
  @return percentage($target / $parent);
}

/* percentage to pixels */
@function percenttopx($percent : 50, $parent: 366) {

  $one-percent: $parent / 100;
  @return $parent - ($percent * $one-percent);
  
}

/* Paddings */
@mixin paddings-breakpoint($paddings, $breakpoint) {

  @include breakpoint($breakpoint down) {
    @each $padding in $paddings {
      .padding-#{$padding}-#{$breakpoint} {
        padding-top: #{$padding}px !important;
        padding-bottom: #{$padding}px !important;
      }
      .padding-#{$padding}-top-#{$breakpoint} {
        padding-top: #{$padding}px !important;
      }
      .padding-#{$padding}-bottom-#{$breakpoint} {
        padding-bottom: #{$padding}px !important;
      }
    }
  }

}

@mixin paddings($paddings) {
  @each $padding in $paddings {
    //$i: index($paddings, $padding);
    .padding-#{$padding} {
      padding-top: #{$padding}px !important;
      padding-bottom: #{$padding}px !important;
    }
    .padding-#{$padding}-top {
      padding-top: #{$padding}px !important;
    }
    .padding-#{$padding}-bottom {
      padding-bottom: #{$padding}px !important;
    }
  }
}

/* Placeholder */
@mixin placeholder($color, $fontstyle:'italic') {
  ::-webkit-input-placeholder { /* WebKit browsers */
    color: $color; font-style: $fontstyle;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color; font-style: $fontstyle;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color; font-style: $fontstyle;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: $color; font-style: $fontstyle;
  }
}


/* burger icon mixin */
// Burger parts
//
// (---) top    -> &:before
// [---] middle -> &
// (---) bottom -> &:after

// Vendor prefixes
$sass-burger-add-vendor-prefixes: true !default;

// Burger
@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
  $burger-height: $height !global;
  $burger-gutter: $gutter !global;

  position: relative;
  margin-top: $height + $gutter;
  margin-bottom: $height + $gutter;

  @if $sass-burger-add-vendor-prefixes {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  user-select: none;

  &, &:before, &:after {
    display: block;
    width: $width;
    height: $height;
    background-color: $color;
    @if $border-radius != 0 {
      border-radius: $border-radius;
    }

    @if $sass-burger-add-vendor-prefixes {
      -webkit-transition-property: background-color, -webkit-transform;
      -moz-transition-property: background-color, -moz-transform;
      -o-transition-property: background-color, -o-transform;
    }
    transition-property: background-color, transform;

    @if $sass-burger-add-vendor-prefixes {
      -webkit-transition-duration: $transition-duration;
      -moz-transition-duration: $transition-duration;
      -o-transition-duration: $transition-duration;
    }
    transition-duration: $transition-duration;
  }

  &:before, &:after {
    position: absolute;
    content: "";
  }

  &:before {
    top: -($height + $gutter);
  }

  &:after {
    top: $height + $gutter;
  }
}


@mixin vertical-burger($height: 30px, $width: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
  $burger-height: $height !global;
  $burger-gutter: $gutter !global;

  position: relative;
  margin-left: $width + $gutter;
  margin-right: $width + $gutter;

  @if $sass-burger-add-vendor-prefixes {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  user-select: none;

  &, &:before, &:after {
    display: block;
    width: $width;
    height: $height;
    background-color: $color;
    @if $border-radius != 0 {
      border-radius: $border-radius;
    }

    @if $sass-burger-add-vendor-prefixes {
      -webkit-transition-property: background-color, -webkit-transform;
      -moz-transition-property: background-color, -moz-transform;
      -o-transition-property: background-color, -o-transform;
    }
    transition-property: background-color, transform;

    @if $sass-burger-add-vendor-prefixes {
      -webkit-transition-duration: $transition-duration;
      -moz-transition-duration: $transition-duration;
      -o-transition-duration: $transition-duration;
    }
    transition-duration: $transition-duration;
  }

  &:before, &:after {
    position: absolute;
    content: "";
  }

  &:before {
    left: $width + $gutter;
  }

  &:after {
    left: -($width + $gutter);
  }
}


// Select parts of the burger
@mixin burger-parts {
  &, &:before, &:after {
    @content;
  }
}

@mixin burger-top {
  &:before {
    @content;
  }
}

@mixin burger-middle {
  & {
    @content;
  }
}

@mixin burger-bottom {
  &:after {
    @content;
  }
}


// Burger animations
@mixin burger-to-cross {
  & {
    background-color: transparent;
  }
  &:before {
    @if $sass-burger-add-vendor-prefixes {
      -webkit-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
      -moz-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
      -ms-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
      -o-transform: translateY($burger-gutter + $burger-height) rotate(45deg);
    }
    transform: translateY($burger-gutter + $burger-height) rotate(45deg);
  }
  &:after {
    @if $sass-burger-add-vendor-prefixes {
      -webkit-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
      -moz-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
      -ms-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
      -o-transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
    }
    transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
  }
}
/* end burger */









/* MIXIN - background-image */
/*
  EXAMPLE
  @include background-image('pattern');
*/
$image-path: '../img' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x_';
@mixin background-image($name, $size:false){
  background-image: url(#{$image-path}/#{$name}.svg);
  @if($size){
    background-size: $size;
  }
  .no-svg &{
    background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

    @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
      background-image: url(#{$image-path}/#{$retina-suffix}#{$name}.#{$fallback-extension});
    }
  }
}

/* MIXIN - font-size */
/*
  EXAMPLE
  @include font-size(14)
*/
@mixin font-size($size, $base-font-size:16) {
  font-size: $size + px;
  font-size: ($size / $base-font-size) + em;
}
/* MIXIN - line-height */
/*
  EXAMPLE
  @include line-height(14)
*/
@mixin line-height($size, $base: 16) {
  line-height: $size + px;
  line-height: ($size / $base) + em;
}

/* MIXIN - normalize-fontline-size */
@mixin normalize-fontline-size($font-size, $line-height: 16, $base-font-size: false){

  @if($base-font-size) {
    @include font-size($font-size, $base-font-size);
  }
  @else {
    @include font-size($font-size);
  }
  
  @include line-height($line-height, $font-size);
}

/* MIXIN - rgba-background */
@mixin rgba-background($hexcolor, $opacity) {
  background-color: $hexcolor;
  background-color: rgba($hexcolor, $opacity);
}


/* MIXIN - rgba-color */
@mixin rgba-color($hexcolor, $opacity) {
  color: $hexcolor;
  color: rgba($hexcolor, $opacity);
}


/* FUNCTION - strip units */
@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}


/// Select all children from the first to `$num`.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - id of the child
@mixin first($num) {
  @if $num == 1 {
    &:first-child {
      @content;
    }
  }
  @else {
    &:nth-child(-n + #{$num}) {
      @content;
    }
  }
}

/// Select all children from the last to `$num`.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - id of the child
@mixin last($num) {
  &:nth-last-child(-n + #{$num}) {
    @content;
  }
}

/// Select all children after the first to `$num`.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - id of the child
@mixin after-first($num) {
  &:nth-child(n + #{$num + 1}) {
    @content;
  }
}

/// Select all children before `$num` from the last.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - id of the child
@mixin from-end($num) {
  &:nth-last-child(#{$num}) {
    @content;
  }
}

/// Select all children between `$first` and `$last`.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin between($first, $last) {
  &:nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}

/// Select all even children between `$first` and `$last`.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin even-between($first, $last) {
  &:nth-child(even):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}

/// Select all odd children between `$first` and `$last`.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin odd-between($first, $last) {
  &:nth-child(odd):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}

/// Select all `$num` children between `$first` and `$last`.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin n-between($num, $first, $last) {
  &:nth-child(#{$num}n):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}


/// Select all children but `$num`.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - id of the child
@mixin all-but($num) {
  &:not(:nth-child(#{$num})) {
    @content;
  }
}

/// Select children each `$num`.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - id of the child
/// @alias every
@mixin each($num) {
  &:nth-child(#{$num}n) {
    @content;
  }
}

/// Select children each `$num`.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - id of the child
@mixin every($num) {
  &:nth-child(#{$num}n) {
    @content;
  }
}

/// Select the `$num` child from the start and the `$num` child from the last.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - id of the child
@mixin from-first-last($num) {
  &:nth-child(#{$num}),
  &:nth-last-child(#{$num}) {
    @content;
  }
}


/// Select the item in the middle of `$num` child. Only works with odd number
/// chain.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - id of the child
@mixin middle($num) {
  &:nth-child(#{round($num / 2)}){
    @content;
  }
}


/// Select all children between the `$num` first and the `$num` last.
/// @group with-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - id of the child
@mixin all-but-first-last($num) {
  &:nth-child(n + #{$num}):nth-last-child(n + #{$num}) {
    @content;
  }
}


/// This quantity-query mixin will only select the first of `$limit` items. It will not
/// work if there is not as much as item as you set in `$limit`.
/// @group Quantity queries
/// @param {number} $limit
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin first-of($limit) {
  &:nth-last-child(#{$limit}):first-child {
    @content;
  }
}

/// This quantity-query mixin will only select the last of `$limit` items. It will not
/// if there is not as much as item as you set in `$limit`.
/// @group Quantity queries
/// @param {number} $limit
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin last-of($limit) {
  &:nth-of-type(#{$limit}):nth-last-of-type(1) {
    @content;
  }
}

/// This quantity-query mixin will select every items if there is at least `$num` items. It will not
/// if there is not as much as item as you set in `$num`.
/// @group Quantity queries
/// @param {number} $limit
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin at-least($num) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);
  &:nth-last-child(n + #{$num}),
  &:nth-last-child(n + #{$num}) ~ #{$child} {
    @content;
  }
}

/// This quantity-query mixin will select every items if there is at most `$num` items. It will not
/// if there is not as much as item as you set in `$num`.
/// @group Quantity queries
/// @param {number} $limit
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin at-most($num) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);
  &:nth-last-child(-n + #{$num}):first-child,
  &:nth-last-child(-n + #{$num}):first-child ~ #{$child} {
    @content;
  }
}

/// This quantity-query mixin will select every items only if there is between `$min` and `$max` items.
/// @group Quantity queries
/// @param {number} $limit
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin in-between($min, $max) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);
  &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child,
  &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child ~ #{$child} {
    @content;
  }
}

/// Select the first exact child
/// @group no-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin first-child() {
  &:first-of-type {
    @content
  }
}

/// Select the last exact child
/// @group no-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin last-child() {
  &:last-of-type {
    @content
  }
}

/// Select all even children.
/// @group no-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin even() {
  &:nth-child(even) {
    @content;
  }
}

/// Select all odd children.
/// @group no-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin odd() {
  &:nth-child(odd) {
    @content;
  }
}

/// Select only the first and last child.
/// @group no-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin first-last() {
  &:first-child,
  &:last-child {
    @content;
  }
}

/// Will only select the child if it’s unique.
/// @group no-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @alias only
@mixin unique() {
  &:only-child {
    @content;
  }
}

/// Will only select the child if it’s unique.
/// @group no-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin only() {
  &:only-child {
    @content;
  }
}

/// Will only select children if they are not unique. Meaning if there is at
/// least 2 children, the style is applied.
/// @group no-arguments
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
@mixin not-unique() {
  &:not(:only-child) {
    @content;
  }
}


/// This mixin is used to automatically sort z-index in numerical order. But it
/// can also sort them in anti-numerical order, depending the parameters you use.
/// @group using functions
/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]
/// @param {number} $num - Number of children
/// @param {string} $direction [forward] - Direction of the sort
/// @param {number} $index [0] - Index of the sorting
@mixin child-index($num, $direction: 'forward', $index: 0) {
  @for $i from 1 through $num {
    @if ($direction == 'forward') {
      &:nth-child(#{$i}) {
        z-index: order-index($i, $index);
        @content;
      }
    } @else if ($direction == 'backward') {
      &:nth-last-child(#{$i}) {
        z-index: order-index($i, $index);
        @content;
      }
    }
  }
}

/// Used by the child-index mixin. It will returned the proper sorted numbers
/// depending on the `$index` value.
/// @access private
/// @param {number} $num - Number of children
/// @param {number} $index - Index of the sorting
@function order-index($i, $index) {
  @return ($index + $i);
}











@mixin transition-delay($delay) {
  -moz-transition-delay:    $delay;
  -o-transition-delay:      $delay;
  -webkit-transition-delay: $delay;
  transition-delay:         $delay;
}






















/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
          to top          : bottom,
          to top right    : bottom left,
          to right top    : left bottom,
          to right        : left,
          to bottom right : top left,
          to right bottom : left top,
          to bottom       : top,
          to bottom left  : top right,
          to left bottom  : right top,
          to left         : right,
          to left top     : right bottom,
          to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}


