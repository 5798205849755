// Header
// -----------------

.header__preheader {
  position:absolute;
  top:0;
  width:100%;
  z-index:2;


  @include breakpoint(769 down){
    position:fixed;
    
    background:$color-white;
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.1);
  }

  @extend .clearfix;
  
  .logo {
    float:left;
    z-index:2;
    margin:20px;
    margin-right:0;

    img {
      height: 36px;
      width: 130px;
      display:block;
    }

    @include breakpoint(1200 up){
      margin:50px 0 50px  60px;
    }

  }

  .cta__block {
    float:right;
    margin:20px;
    margin-left:0;

    @include breakpoint(1200 up){
      margin:50px 60px 50px  0;
    }
    
    .button {
      vertical-align: middle;
      margin:0 0 0 25px;

      @include breakpoint(769 down){
        margin:0;
      }
      
    }

  }
  
}

.header__teaser {
  position:relative;
  z-index:1;
  overflow:hidden;

  
  .close-btn {
    position:fixed;
    z-index:100;
    height:30px;
    width:30px;
    line-height:30px;
    display:none;
    color: #333;

    text-decoration: none;
    text-align: center;
    opacity: .65;
    padding: 0;
    font-style: normal;
    font-size: 35px;
    font-family: Arial,Baskerville,monospace;
    
    
    &.is-visible {
      display:block;
    }
    
  }
  
  &.teaser--large {
    height:90vh;
    max-height:800px;
    @include breakpoint(1366 down){
      height:70vh;
      min-height:700px;
    }

    @include breakpoint(1025 down){
      min-height:500px;
    }

    @include breakpoint(769 down){
      height:auto;
      min-height:1px;
    }
  }

  .teaser__mobile {
    position:relative;
    display:none;
    
    @include breakpoint(769 down){
        display:block;
    }


    .mobile__bg {
      background: url('../images/parallax/shockmaster-layer-1.jpg') no-repeat center center;
      background-size:cover;
      position:absolute;
      height:100%;
      width:100%;
      z-index:1;

      .page--landing-400 & {
        background:  url('../images/parallax/gymna-400/gymna-layer-1.jpg') no-repeat center center;
        background-size:cover;
        
        &:before {
          content:"";
          background: url('../images/parallax/gymna-400/gymna-layer-2.png');
          position:absolute;
          height:100%;
          width:100%;
          z-index:2;

        }
        
      }
      
    }
    .mobile__image {
      position:relative;
      z-index:3;
      padding-top:110px;

      .page--landing-400 & {
        padding-bottom: 30px;
      }
      
      img {
        margin:0 auto;
        max-width:90%;

        .page--landing-400 & {
          max-width:50%;
          
        }
        
      }
      

    }
  }

  .teaser__image {
    position:absolute;
    height:100%;
    width:100%;
    top:0;
    left:0;
    z-index:0;

    background: url('../images/parallax/shockmaster-white.png') repeat-x bottom;

    @include breakpoint(769 down){
      display:none !important;
    }

    img {
      //max-width:none;
      //width:100%;
    }

    @include breakpoint(769 down){
      position:relative;
      height:80vh;
      min-height:350px;
    }

    @include breakpoint(small down){
      min-height:1px;
    }


  }

  .teaser__pointers {
    position:absolute;
    display:block;
    bottom:0;
    left:50%;
    width: 100%;
    height:100%;
    max-width:1440px;
    margin-left:-720px;

      @include breakpoint(1440 down) {
        max-width: 100%;
        margin-left: 0;
        left: 0;
      }
    
    .pointer {

      display:none;
      position:absolute;
      z-index:5;

      &.animated.is-animated {
        //@include opacity(0);
        animation-duration: 1.2s;
        animation-fill-mode: both;
        animation-name: bounceIn;
        display:block;

        
        &.pointer--1 {
          animation-delay: 1s;
        }

        &.pointer--2 {
          animation-delay: 1.5s;
        }

        &.pointer--3 {
          animation-delay: 2s;
        }

        &.pointer--4 {
          animation-delay: 2.5s;
        }

      }

      @keyframes pointerPulse {
        from {
          transform: scale3d(1, 1, 1);
        }

        50% {
          transform: scale3d(1.3, 1.3, 1.3);
        }

        to {
          transform: scale3d(1, 1, 1);
        }
      }
      
      
      .pointer__bull {
        height:36px;
        width:36px;
        margin-left:-18px;
        margin-top:-18px;
        background:#fff;
        @include border-radius(100%);
        display:block;
        overflow:hidden;
        border:5px solid $brand-primary;
        //@include transition(all,.2s);
        cursor: pointer;
        
        &.animate-pulse-refresh {
          animation-duration: .8s;
          animation-fill-mode: both;
          animation-iteration-count: 1;
          animation-name: pointerPulse;
        }

        &:hover {
          animation-duration: .8s !important;
          animation-fill-mode: both !important;
          animation-name: pointerPulse !important;
          animation-iteration-count: infinite !important;
        }

      &.fadeOut {
          animation-delay: 0s !important;
          animation-duration: .5s !important;
          animation-fill-mode: both !important;
          animation-name: fadeOut !important;
          animation-iteration-count: 1 !important;
        }
      }

      &.pointer--1 {
        left: 50%;
        top: 90%;

        .animate-pulse-refresh {
            animation-delay: 0s;
        }
        
      }

      &.pointer--2 {
        left: 80%;
        top: 70%;

        .animate-pulse-refresh {
            animation-delay: 2s;
        }
        
      }

    }

  }

  &.slide2,
  &.slide3 {
    .layer-1 {
      display:none;
      @include breakpoint(769 down){
        display:block;
      }
    }

    .teaser__pointers {
      @include breakpoint(1025 down){
        max-width:100%;
        left:0;
        margin-left:0;
      }
    }

  }

  .teaser__footer {
    position:absolute;
    //bottom: 20%;
    bottom: 30%;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index:1;
    pointer-events:none;

    a.button {
      pointer-events:auto;
    }

    > div {
      position: relative;
      @include breakpoint(769 down) {
          position:static;
          flex: 0 0 100%;
          max-width: 100%;
          margin-left:0;
      }
    }

    // reset gutter
    @include breakpoint(769 down) {
      position: relative;
      margin:0;
      pointer-events:auto;

      
      > .column {
        padding:0;
      }
      
    } 
      
  }


  .teaser__title {
    color: $color-white;
    z-index:2;

    .page--landing-400 & {
      color: $brand-secundary;
      .title {
        text-shadow:none !important;
      }
    }
    
    &.color-brand-secundary {
      color: $brand-secundary;
      .title {
        text-shadow:none !important;
      }
    }
    
    @include breakpoint(769 down){
      color:$brand-secundary;
    }
    
    .title__label {
      @extend .label;
      font-size:rem-calc(30px);
      line-height:50px;
      padding: 0 14px;
      margin-bottom:25px;

      &.bg-brand-secundary {
        background: $brand-secundary;
        color:$color-white;
      }

      @include breakpoint(large up) {
        max-height: 0px;
        overflow: hidden;

        &.animated.is-animated {
          body.content--loaded & {
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-name: slideHeight;
            animation-delay: .2s;
          }
        }
      }

      @include breakpoint(769 down){
        @include flex-grid-column();
        white-space:normal;
        margin-bottom:rem-calc(10px);
      }
      
    }

    .title {
      @include text-shadow(0,2px,30px,rgba(0,0,0,0.5));
      
      @include breakpoint(769 down){
        text-shadow: none;
        @include flex-grid-column();
      }

      @include breakpoint(large up) {
        @include opacity(0);
        &.animated.is-animated {
          body.content--loaded & {
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-name: fadeIn;
            animation-delay: .5s;
          }
        }
      }
      h1, .h1 {
        
        .page--landing-400 & {

          font-weight: bold;
          margin-top:20px;
          @include breakpoint(large up){
            font-size: rem-calc(65px);
            margin-top:0;
            line-height: rem-calc(85px);
          }
        }
        
      }
      
      h2, .h2 {

        .page--landing-400 & {
          font-weight: 500;

          font-size: rem-calc(20px);
          line-height: rem-calc(31px);

          @include breakpoint(large up){
            font-size: rem-calc(50px);
            line-height: rem-calc(61px);
            margin-bottom:30px;
          }
        }
      }
      

    }

  }

}

.header__navigation {

  position:relative;
  z-index:3;
  margin-top:-10px;
  display:none;
  
  @include breakpoint(769 down){
    display:block;
  }
  
  &:after {
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.1);
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    content: "";
    z-indeX: 0;
    height: 10px;
  }
  
  
  .navigation {

    position:relative;
    z-index:1;
    background:$color-white;


    ul {
      
      list-style: none;
      margin:0;
      padding:0;
      
      li {
        
        a {
          
          display:block;
          border-top:1px solid rgba($brand-secundary, .05);
          padding:20px 0;
          @include flex-grid-column();

          font-size:rem-calc(20px);
          line-height:1em;
          font-weight:600;
          color:$brand-primary;
          
          &:after {
            @include icomoon();
            content: "\e904";
            margin-left:20px;
            vertical-align: middle;
          }
          
          
          &:focus,
          &:hover {
            color:$brand-secundary;
          }
          
        }
        
      }
    }
  }
}

.header__teaser-slider {
  background:#fff;
}


[data-slick-index="1"], [data-slick-index="2"]{
  .teaser__footer {
    @include breakpoint(1024 up){
      margin-bottom:-50px;
    }
  }
}

