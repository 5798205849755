
// Footer
// -----------------

footer {

  font-size:rem-calc(16px);
  line-height:rem-calc(19px);

  color:$brand-grey-light;
  a {
    color:$brand-grey-light;
    &:focus,
    &:hover {
      color:darken($brand-grey-light,30%);
    }
  }

  .footer__inner {
    padding:65px 0;
  }

  .logo {
    display:inline-block;
    margin-bottom:10px;

    img {
      height: 36px;
      width: 130px;
      display:block;
    }
  }

  .copyright {
    display:block;
    @include breakpoint(large down){
      margin-bottom:30px;
    }
  }

  .navigation {
    list-style:none;
    margin:0;
    padding:0;

    @include breakpoint(medium up){
      margin-top:12px;
    }

    li {
      padding:0;
      margin:0;
    }

  }
  
  
}