// Spin block
// -----------------
.spin__block {
  position:absolute;
  left: -300px;
  top: -310px;  
  width:100%;
  height:100%;
  z-index:0;
  .spin {
    .line {
      opacity:.4
    }
  }
}

// Quote block
// -----------------
.quote__block {
    display: inline-block;
    position:relative;

    .quote__name {
      margin-top:20px;
      .title {
        font-weight:600;
      }
    }
    
}
.quote__block--text-right {

  display: inline-block;
  position:relative;

  .quote__name {

    margin-top:20px;

    .title {
      font-weight:600;
    }
  }
  .quote__slogan {

    font-family: $font-header;
    font-size: rem-calc(22px);
    line-height: rem-calc(26px);    
    font-weight:700;
    margin:1em 0;
    color:$brand-primary;
    
    .text-shadow {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 70px #fff, 0 0 80px #fff, 0 0 100px #fff, 0 0 150px #fff;
      color:#fff;
      display:none;
    }
    
    
    @include breakpoint(large up){
        position:absolute;
        left:50%;
        bottom:0;
        width:90%;
        margin:0;
        font-size: rem-calc(32px);
        line-height: rem-calc(36px);

        .text {
          position:absolute;
          top:0;
          left:0;
          width:100%;
        }
        
        .text-shadow {
            display:block;
        }

    }

  }
}

// CTA block
// -----------------
.cta__block {
  
  margin-top:30px;
  
  @include breakpoint(medium){
    margin-top:20px;
  }

  @include breakpoint(large up){
    margin-top:60px;
  }
  
  .link {
    vertical-align: middle;
    margin-right:15px;
    margin-bottom:10px;
    display:inline-block;
  }
  .button {
    vertical-align: middle;
    margin-bottom:10px;
  }
  
  .title {
    font-weight:bold;
    vertical-align: middle;
    margin-right:15px;
    margin-bottom:10px;
    display:inline-block;
  }
  
}


// Image block
// -----------------

.image__block {

  @extend .clearfix;
  position:relative;

  &.gymna400vip {
    
    position:absolute;
    @include breakpoint(medium up){
      bottom: -220px;
      left:60%;
    }

    @include breakpoint(medium down) {
      bottom:-100px;
    }

    @include breakpoint(small down) {
      position:relative;
      left:50%;
      transform: translateX(-50%);
      text-align:center;
      bottom:-50px;
    }
    
  }

  .logo {
    float:right;
    margin-bottom:50px;
  }

  .image__overlay {
    clear:both;
    max-width:430px;
    width:100%;
    display:block;
    float:left;
    position:relative;
    z-index:0;

    &.right-bottom-top {
      margin-top:-55px;
      float:right;
      position:relative;
      z-index:1;
    }

    &.right-bottom-bottom {
      float:right;
      position:relative;
      z-index:0;
    }

    &.right-top-bottom {
      float:left;
      position:relative;
      z-index:0;
    }

    &.left-bottom-top {
      margin-top:-55px;
      float:left;
      position:relative;
      z-index:1;
    }

    &.left-top-top {
      margin-top:55px;
      float:left;
      position:relative;
      top:0;
      z-index:1;

      &-large {
        @include breakpoint(large up){
          margin-top:55px;
          float:left;
          position:relative;
          top:0;
          z-index:1;

          > a, > div {
            @include breakpoint(large up){
              position:absolute;
              top:0;
              right:0;
              width:100%;
              transform: translateY(-100%);
            }
          }

        }
      }

      > a, > div {
          position:absolute;
          top:0;
          right:0;
          width:100%;
          transform: translateY(-100%);
        }
    }

  }
  + .help__block {
    margin-top:35px;
  }

}

// Form block
// -----------------


.form__block {

  color:$brand-secundary;
  
  h2, .h2 {
    font-size: rem-calc(30px);
    line-height: rem-calc(35px);
    margin-bottom:40px;
    max-width:90%;
  }

  .block__overlay {
    background:$color-white;
    padding:40px;
    
    @include breakpoint(small down){
      padding:15px;  
    }
    
  }

  input {
    margin-bottom:15px;
  }

  textarea {
    height:100px;
    resize:none;
    margin-bottom:15px;
  }

  button {
    margin-top:23px;
    margin-bottom:0;
  }

}

// Help block
// -----------------


.help__block {
  font-size: rem-calc(14px);
  line-height: rem-calc(22px);
  color:rgba($brand-secundary,.5);
}

// USP block
// -----------------


.usp__block {
  
  h3, .h3 {
    .page--landing-400 & {
      color:$brand-primary;
    }
  }

  ul {

    margin-left:0;

    li {

      list-style:none;
      position:relative;
      padding-left: 23px;
      margin-bottom:20px;

      &:before {
        content: "";
        margin-right: 8px;
        background:$brand-primary;
        @include border-radius(100%);
        height:14px;
        width:14px;
        line-height: 14px;
        display:inline-block;
        vertical-align:middle;
        position:absolute;
        left:0;
        top:8px;
      }


    }
  }


}

// Chart block
// -----------------

.chart__block {

  margin-top:20px;
  display:none;

  @include breakpoint(large up){
    display:block;
  }

  .chart__item {
    display:inline-block;
    margin:0 25px 25px 0;
    &:last-child {
      margin-right:0;
    }
  }

  &.text-center {
    .chart__item {
      margin-bottom:0;  
    }
    
  }

}

// Specifications block
// -----------------

.specifications__block {
  ul {
    list-style: none;
    margin:0;
    padding:0;

    li {
      position: relative;
      padding-left:30px;
      font-weight:600;
      line-height:24px;
      margin-bottom:15px;

      font-size: rem-calc(20px);
      line-height: rem-calc(24px);
      white-space: nowrap;

      a {
        color:$brand-secundary;
        @include transition(all,.2s);
        
        &:before {
          height:18px;
          width:18px;
          border:solid $brand-primary 2px;
          @include border-radius(100%);
          content:"";
          display:inline-block;
          position:absolute;
          left:0;
          top:5px;
          @include transition(all,.2s);
        }

        &:focus {
          color:inherit;
        }
        
        &.is-active,
        &:hover {
          color:$brand-primary;
          &:before {
            background:$brand-primary;
          }
        }
        
      }

    }
  }
}

// Text block
// -----------------

.text__block {
  
  ol {

    margin-left:0;
    margin-top:45px;

    li {
      counter-increment: step-counter;
      list-style:none;
      position:relative;
      padding-left: 37px;

      &:before {
        content: counter(step-counter);
        margin-right: 5px;
        color: $color-white;
        font-weight: bold;
        background:$brand-primary;
        @include border-radius(100%);
        height:23px;
        width:23px;
        font-size: rem-calc(16px);
        line-height: 23px;
        display:inline-block;
        text-align:center;
        vertical-align:middle;
        position:absolute;
        left:0;
        top:0;
      }

      p {
        font-size:rem-calc(16px);
        line-height:rem-calc(22px);
      }

    }

  }

  a {
    color:$button-background;
    &:focus,
    &:hover {
      color:$button-background-hover;
    }
    &.button {
      @extend .button;
    }

    &.link {
      @extend .link;
    }
  }
}

// Controls block
// -----------------
.controls__block {
  
  margin-bottom:40px;
  
  .title {
    font-weight:700;
    color:$brand-primary;
    margin-bottom:20px;
  }

  .label {
    border-radius:26px;
    color:#fff;
    background-color: #CFCFCF;
    font-weight: 600;
    font-size:rem-calc(18px);
    line-height:rem-calc(24px);
    padding:5px 22px;
    @include transition(all,.2s);
    margin-right:10px;
    cursor:pointer;
    position:relative;
    
    &.is-active,
    &:hover,
    &:focus {
      background:$button-background;
      color:#fff;
    }
    
    &.is-active {
      
      &:after {
        content:"";
        position:absolute;
        bottom:-10px;
        left:50%;
        transform:translateX(-50%);
        
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        border-top: 10px solid $button-background;
        
      }
      
      &:focus,
      &:hover {
        background:  scale-color($button-background, $lightness: -15%);
        &:after {
          border-top-color:   scale-color($button-background, $lightness: -15%);
        }
      }
    }
  }
  
}

// Intro block
// -----------------
.intro__block {
  @extend .text__block;
  p {
    font-size:rem-calc(20px);
    line-height:rem-calc(26px);
  }
  
}

// Icon block
// -----------------
.icon__block {

  text-align: center;
  margin-bottom:20px;
  
  .icon {
    display:inline-block;
    color:#fff;
    margin-bottom:30px;
    
    @include icomoon();
    font-size:30px;
    
    &:before {
      content: "\e908";
      color: #994d9d;
    }
    
  }
  
  .logo {
    margin-bottom:20px;
    display:inline-block;
  }
  
  .icon__text {
    font-size:rem-calc(20px);
    line-height:rem-calc(26px);
    
    a {
      color:$brand-primary;
      &:hover {
        text-decoration: underline;
      }
    }
     
  }
  
  .icon__line {
    height: 1px;	
    width: 34px;
    margin-top:30px;
    background-color:$brand-primary;
    margin:0 auto;
  }
  
  ul {
    list-style: none;
    font-size:rem-calc(18px);
    
    li {
      margin-bottom:5px;
      span {
        background: $brand-primary;
        color: #fff;
        display: inline;
        padding:0 5px;
      }
    }
  }
  
  h4 {
    font-weight: bold;	
    font-size:rem-calc(20px);
    line-height:rem-calc(26px);
    margin-bottom:10px;
  }
  
}