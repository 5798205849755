@charset "UTF-8";
/* -------------------------------------------------------------
 *  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
 *  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets
 *
 *  Mixins available (usage: @include border-radius(10px);:
 *    -   background-gradient     - arguments: Start Color: #3C3C3C, End Color: #999999
 *    -   background-size         - arguments: Width: 100%, Height: 100%
 *    -   border-radius           - arguments: Radius: 5px
 *    -   border-radius-separate  - arguments: Top Left: 5px, Top right: 5px, Bottom Left: 5px, Bottom Right: 5px ( @include border-radius-separate(150px, 0, 150px, 0) )
 *    -   box                     - arguments: Orientation: horizontal, Pack: center, Align: center
 *    -   box-rgba                - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
 *    -   box-shadow              - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
 *    -   box-sizing              - arguments: Type: border-box
 *    -   columns                 - arguments: Count: 3, Gap: 10
 *    -   double-borders          - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
 *    -   flex                    - arguments: Value: 1
 *    -   flip                    - arguments: ScaleX: -1
 *    -   font-face               - arguments: Font Family: myFont, Eot File Src: myFont.eot, Woff File Src: myFont.woff, Ttf File Src: myFont.ttf
 *    -   opacity                 - arguments: Opacity: 0.5
 *    -   outline radius          - arguments: Radius: 5px
 *    -   resize                  - arguments: Direction: both
 *    -   rotate                  - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
 *    CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
 *    -   text-shadow             - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
 *    -   transform               - arguments: Parameters: null
 *    -   transition              - arguments: What: all, Length: 1s, Easing: ease-in-out
 *    -   transition-multiple     - arguments: Parameters: null ( @incl...(transform 0.2s ease-in-out, opacity 0.2s ease-in-out);)
 *    -   triple-borders          - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0
 *    -   vertical-align          - arguments: child selector; Use on parent with vertical aligned object as parameter
 *    -   center-align            - arguments: child selector; Use on parent with vertical aligned object as parameter
 *    -   placeholder             - arguments: color and fontstyle for placeholder
 *    -   animation               - arguments: Parameters: null    ( @include animation( come-in 0.8s ease forwards); )
 *    -   keyframes               - arguments: Animation name   ( @include keyframes(come-in){...} )
 *    -   burger                  - arguments: Width line (px), height line (px), space between lines (px), color (#) for animation call @include burger-to-cross; on hover
 *
 *-------------------------------------------------------------*/
/* BACKGROUND GRADIENT */
/* BACKGROUND SIZE */
/* BORDER RADIUS */
/* BOX */
/* BOX RGBA */
/* BOX SHADOW */
/* BOX SIZING */
/* COLUMNS */
/* DOUBLE BORDERS */
/* FLEX */
/* FLIP */
/* FONT FACE */
/* OPACITY */
/* OUTLINE RADIUS */
/* RESIZE */
/* ROTATE*/
/* TEXT SHADOW */
/* TRANSFORM  */
/* TRANSITION */
/* TRIPLE BORDERS */
/* VERTICAL ALIGN MIDDLE */
/* use this mixin on parent child selector as parameter */
/* center ALIGN MIDDLE */
/* use this mixin on parent child selector as parameter */
/* ANIMATION */
/* KEYFRAMES */
/* pxtopercent */
/* percentage to pixels */
/* Paddings */
/* Placeholder */
/* burger icon mixin */
/* end burger */
/* MIXIN - background-image */
/*
  EXAMPLE
  @include background-image('pattern');
*/
/* MIXIN - font-size */
/*
  EXAMPLE
  @include font-size(14)
*/
/* MIXIN - line-height */
/*
  EXAMPLE
  @include line-height(14)
*/
/* MIXIN - normalize-fontline-size */
/* MIXIN - rgba-background */
/* MIXIN - rgba-color */
/* FUNCTION - strip units */
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?90eas6");
  src: url("../fonts/icomoon.eot?90eas6#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?90eas6") format("truetype"), url("../fonts/icomoon.woff?90eas6") format("woff"), url("../fonts/icomoon.svg?90eas6#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-right:before,
.icon-help:before,
.icon-refresh:before,
.icon-triangle:before,
.mpf-video:before,
.icon-quote:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-quote:before {
  content: "\e901";
  color: #4a556b; }

.icon-help:before {
  content: "\e902";
  color: #ff6200; }

.icon-refresh:before {
  content: "\e903";
  color: #4a556b; }

.icon-triangle:before, .mpf-video:before {
  content: "\e900";
  color: #fff; }

.icon-arrow-right:before {
  content: "\e904";
  color: #fff; }

.icon-easy-to-use:before {
  content: "\e905";
  color: #994d9d; }

.icon-experience:before {
  content: "\e906";
  color: #994d9d; }

.icon-expertise:before {
  content: "\e907";
  color: #994d9d; }

.icon-plus-icoon:before {
  content: "\e908";
  color: #994d9d; }

.icon-expertise {
  height: 105px;
  width: 149px;
  display: inline-block;
  margin-bottom: 10px;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 105px;
  content: "\e907";
  color: #FF6200; }

.icon-easytouse {
  height: 90px;
  width: 149px;
  display: inline-block;
  margin-bottom: 10px;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 90px;
  color: #FF6200; }
  .icon-easytouse:before {
    content: "\e905"; }

.icon-readbook {
  height: 105px;
  width: 149px;
  display: inline-block;
  margin-bottom: 10px;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 105px;
  color: #994d9d; }
  .icon-readbook:before {
    content: "\e906"; }

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 37.5em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=37.5em&landscapebr=48em&large=64.0625em&xlarge=90.125em&xxlarge=120em"; }

html {
  box-sizing: border-box;
  font-size: 1.125rem; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: "myriad-pro", sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #4A556B;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 1160px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -1.11111rem;
    margin-left: -1.11111rem; }
    @media print, screen and (min-width: 37.5em) {
      .row .row {
        margin-right: -1.38889rem;
        margin-left: -1.38889rem; } }
    @media print, screen and (min-width: 48em) {
      .row .row {
        margin-right: -0.83333rem;
        margin-left: -0.83333rem; } }
    @media print, screen and (min-width: 64.0625em) {
      .row .row {
        margin-right: -0.83333rem;
        margin-left: -0.83333rem; } }
    @media screen and (min-width: 90.125em) {
      .row .row {
        margin-right: -0.83333rem;
        margin-left: -0.83333rem; } }
    @media screen and (min-width: 120em) {
      .row .row {
        margin-right: -0.83333rem;
        margin-left: -0.83333rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 1.11111rem;
  padding-left: 1.11111rem;
  min-width: 0; }
  @media print, screen and (min-width: 37.5em) {
    .column, .columns {
      padding-right: 1.38889rem;
      padding-left: 1.38889rem; } }
  @media print, screen and (min-width: 48em) {
    .column, .columns {
      padding-right: 0.83333rem;
      padding-left: 0.83333rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 1.11111rem;
  padding-left: 1.11111rem; }

@media print, screen and (min-width: 37.5em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 37.5em) and (min-width: 37.5em) {
  .medium-expand {
    flex: 1 1 0px; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 37.5em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 37.5em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1.38889rem;
    padding-left: 1.38889rem; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .landscapebr-offset-0 {
    margin-left: 0%; }
  .landscapebr-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .landscapebr-offset-1 {
    margin-left: 8.33333%; }
  .landscapebr-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .landscapebr-offset-2 {
    margin-left: 16.66667%; }
  .landscapebr-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .landscapebr-offset-3 {
    margin-left: 25%; }
  .landscapebr-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .landscapebr-offset-4 {
    margin-left: 33.33333%; }
  .landscapebr-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .landscapebr-offset-5 {
    margin-left: 41.66667%; }
  .landscapebr-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .landscapebr-offset-6 {
    margin-left: 50%; }
  .landscapebr-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .landscapebr-offset-7 {
    margin-left: 58.33333%; }
  .landscapebr-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .landscapebr-offset-8 {
    margin-left: 66.66667%; }
  .landscapebr-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .landscapebr-offset-9 {
    margin-left: 75%; }
  .landscapebr-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .landscapebr-offset-10 {
    margin-left: 83.33333%; }
  .landscapebr-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .landscapebr-offset-11 {
    margin-left: 91.66667%; }
  .landscapebr-up-1 {
    flex-wrap: wrap; }
    .landscapebr-up-1 > .column, .landscapebr-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .landscapebr-up-2 {
    flex-wrap: wrap; }
    .landscapebr-up-2 > .column, .landscapebr-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .landscapebr-up-3 {
    flex-wrap: wrap; }
    .landscapebr-up-3 > .column, .landscapebr-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .landscapebr-up-4 {
    flex-wrap: wrap; }
    .landscapebr-up-4 > .column, .landscapebr-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .landscapebr-up-5 {
    flex-wrap: wrap; }
    .landscapebr-up-5 > .column, .landscapebr-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .landscapebr-up-6 {
    flex-wrap: wrap; }
    .landscapebr-up-6 > .column, .landscapebr-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .landscapebr-up-7 {
    flex-wrap: wrap; }
    .landscapebr-up-7 > .column, .landscapebr-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .landscapebr-up-8 {
    flex-wrap: wrap; }
    .landscapebr-up-8 > .column, .landscapebr-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .landscapebr-expand {
    flex: 1 1 0px; } }

.row.landscapebr-unstack > .column, .row.landscapebr-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 48em) {
    .row.landscapebr-unstack > .column, .row.landscapebr-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-collapse > .column, .landscapebr-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .landscapebr-uncollapse > .column, .landscapebr-uncollapse > .columns {
    padding-right: 0.83333rem;
    padding-left: 0.83333rem; } }

@media print, screen and (min-width: 64.0625em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64.0625em) and (min-width: 64.0625em) {
  .large-expand {
    flex: 1 1 0px; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 64.0625em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 64.0625em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.83333rem;
    padding-left: 0.83333rem; } }

@media screen and (min-width: 90.125em) {
  .xlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xlarge-offset-0 {
    margin-left: 0%; }
  .xlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .xlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .xlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .xlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .xlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .xlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .xlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .xlarge-up-1 {
    flex-wrap: wrap; }
    .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xlarge-up-2 {
    flex-wrap: wrap; }
    .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xlarge-up-3 {
    flex-wrap: wrap; }
    .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xlarge-up-4 {
    flex-wrap: wrap; }
    .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xlarge-up-5 {
    flex-wrap: wrap; }
    .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xlarge-up-6 {
    flex-wrap: wrap; }
    .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xlarge-up-7 {
    flex-wrap: wrap; }
    .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xlarge-up-8 {
    flex-wrap: wrap; }
    .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 90.125em) and (min-width: 90.125em) {
  .xlarge-expand {
    flex: 1 1 0px; } }

.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 90.125em) {
    .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
      flex: 1 1 0px; } }

@media screen and (min-width: 90.125em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.83333rem;
    padding-left: 0.83333rem; } }

@media screen and (min-width: 120em) {
  .xxlarge-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .xxlarge-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .xxlarge-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .xxlarge-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .xxlarge-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .xxlarge-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .xxlarge-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .xxlarge-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .xxlarge-up-1 {
    flex-wrap: wrap; }
    .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .xxlarge-up-2 {
    flex-wrap: wrap; }
    .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .xxlarge-up-3 {
    flex-wrap: wrap; }
    .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .xxlarge-up-4 {
    flex-wrap: wrap; }
    .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .xxlarge-up-5 {
    flex-wrap: wrap; }
    .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .xxlarge-up-6 {
    flex-wrap: wrap; }
    .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .xxlarge-up-7 {
    flex-wrap: wrap; }
    .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .xxlarge-up-8 {
    flex-wrap: wrap; }
    .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media screen and (min-width: 120em) and (min-width: 120em) {
  .xxlarge-expand {
    flex: 1 1 0px; } }

.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%; }
  @media screen and (min-width: 120em) {
    .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
      flex: 1 1 0px; } }

@media screen and (min-width: 120em) {
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.83333rem;
    padding-left: 0.83333rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 2.22222rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 37.5em) {
    .column-block {
      margin-bottom: 2.77778rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }
  @media print, screen and (min-width: 48em) {
    .column-block {
      margin-bottom: 1.66667rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

display: flex;
flex-flow: row wrap;
.grid-container {
  padding-right: 0.55556rem;
  padding-left: 0.55556rem;
  max-width: 1160px;
  margin: 0 auto; }
  @media print, screen and (min-width: 37.5em) {
    .grid-container {
      padding-right: 0.83333rem;
      padding-left: 0.83333rem; } }
  .grid-container.fluid {
    padding-right: 0.55556rem;
    padding-left: 0.55556rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 37.5em) {
      .grid-container.fluid {
        padding-right: 0.83333rem;
        padding-left: 0.83333rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 37.5em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 48em) {
  .grid-x > .landscapebr-shrink, .grid-x > .landscapebr-full, .grid-x > .landscapebr-1, .grid-x > .landscapebr-2, .grid-x > .landscapebr-3, .grid-x > .landscapebr-4, .grid-x > .landscapebr-5, .grid-x > .landscapebr-6, .grid-x > .landscapebr-7, .grid-x > .landscapebr-8, .grid-x > .landscapebr-9, .grid-x > .landscapebr-10, .grid-x > .landscapebr-11, .grid-x > .landscapebr-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64.0625em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

@media screen and (min-width: 90.125em) {
  .grid-x > .xlarge-shrink, .grid-x > .xlarge-full, .grid-x > .xlarge-1, .grid-x > .xlarge-2, .grid-x > .xlarge-3, .grid-x > .xlarge-4, .grid-x > .xlarge-5, .grid-x > .xlarge-6, .grid-x > .xlarge-7, .grid-x > .xlarge-8, .grid-x > .xlarge-9, .grid-x > .xlarge-10, .grid-x > .xlarge-11, .grid-x > .xlarge-12 {
    flex-basis: auto; } }

@media screen and (min-width: 120em) {
  .grid-x > .xxlarge-shrink, .grid-x > .xxlarge-full, .grid-x > .xxlarge-1, .grid-x > .xxlarge-2, .grid-x > .xxlarge-3, .grid-x > .xxlarge-4, .grid-x > .xxlarge-5, .grid-x > .xxlarge-6, .grid-x > .xxlarge-7, .grid-x > .xxlarge-8, .grid-x > .xxlarge-9, .grid-x > .xxlarge-10, .grid-x > .xxlarge-11, .grid-x > .xxlarge-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 37.5em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .grid-x > .landscapebr-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .landscapebr-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .landscapebr-1 {
    width: 8.33333%; }
  .grid-x > .landscapebr-2 {
    width: 16.66667%; }
  .grid-x > .landscapebr-3 {
    width: 25%; }
  .grid-x > .landscapebr-4 {
    width: 33.33333%; }
  .grid-x > .landscapebr-5 {
    width: 41.66667%; }
  .grid-x > .landscapebr-6 {
    width: 50%; }
  .grid-x > .landscapebr-7 {
    width: 58.33333%; }
  .grid-x > .landscapebr-8 {
    width: 66.66667%; }
  .grid-x > .landscapebr-9 {
    width: 75%; }
  .grid-x > .landscapebr-10 {
    width: 83.33333%; }
  .grid-x > .landscapebr-11 {
    width: 91.66667%; }
  .grid-x > .landscapebr-12 {
    width: 100%; } }

@media print, screen and (min-width: 64.0625em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

@media screen and (min-width: 90.125em) {
  .grid-x > .xlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xlarge-1 {
    width: 8.33333%; }
  .grid-x > .xlarge-2 {
    width: 16.66667%; }
  .grid-x > .xlarge-3 {
    width: 25%; }
  .grid-x > .xlarge-4 {
    width: 33.33333%; }
  .grid-x > .xlarge-5 {
    width: 41.66667%; }
  .grid-x > .xlarge-6 {
    width: 50%; }
  .grid-x > .xlarge-7 {
    width: 58.33333%; }
  .grid-x > .xlarge-8 {
    width: 66.66667%; }
  .grid-x > .xlarge-9 {
    width: 75%; }
  .grid-x > .xlarge-10 {
    width: 83.33333%; }
  .grid-x > .xlarge-11 {
    width: 91.66667%; }
  .grid-x > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .grid-x > .xxlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xxlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xxlarge-1 {
    width: 8.33333%; }
  .grid-x > .xxlarge-2 {
    width: 16.66667%; }
  .grid-x > .xxlarge-3 {
    width: 25%; }
  .grid-x > .xxlarge-4 {
    width: 33.33333%; }
  .grid-x > .xxlarge-5 {
    width: 41.66667%; }
  .grid-x > .xxlarge-6 {
    width: 50%; }
  .grid-x > .xxlarge-7 {
    width: 58.33333%; }
  .grid-x > .xxlarge-8 {
    width: 66.66667%; }
  .grid-x > .xxlarge-9 {
    width: 75%; }
  .grid-x > .xxlarge-10 {
    width: 83.33333%; }
  .grid-x > .xxlarge-11 {
    width: 91.66667%; }
  .grid-x > .xxlarge-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.55556rem;
  margin-right: -0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-margin-x {
      margin-left: -0.83333rem;
      margin-right: -0.83333rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.11111rem);
    margin-left: 0.55556rem;
    margin-right: 0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.66667rem);
      margin-left: 0.83333rem;
      margin-right: 0.83333rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.11111rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.11111rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.11111rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.11111rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.11111rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.11111rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.11111rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.11111rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.11111rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.11111rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.11111rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.11111rem); }
  @media print, screen and (min-width: 37.5em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.66667rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.66667rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.66667rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.66667rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.66667rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.66667rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.66667rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.66667rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.66667rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.66667rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.66667rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.66667rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.66667rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.66667rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.66667rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.66667rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.66667rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.66667rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.66667rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.66667rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.66667rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.66667rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.66667rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.66667rem); } }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x > .landscapebr-auto {
      width: auto; }
    .grid-margin-x > .landscapebr-shrink {
      width: auto; }
    .grid-margin-x > .landscapebr-1 {
      width: calc(8.33333% - 1.66667rem); }
    .grid-margin-x > .landscapebr-2 {
      width: calc(16.66667% - 1.66667rem); }
    .grid-margin-x > .landscapebr-3 {
      width: calc(25% - 1.66667rem); }
    .grid-margin-x > .landscapebr-4 {
      width: calc(33.33333% - 1.66667rem); }
    .grid-margin-x > .landscapebr-5 {
      width: calc(41.66667% - 1.66667rem); }
    .grid-margin-x > .landscapebr-6 {
      width: calc(50% - 1.66667rem); }
    .grid-margin-x > .landscapebr-7 {
      width: calc(58.33333% - 1.66667rem); }
    .grid-margin-x > .landscapebr-8 {
      width: calc(66.66667% - 1.66667rem); }
    .grid-margin-x > .landscapebr-9 {
      width: calc(75% - 1.66667rem); }
    .grid-margin-x > .landscapebr-10 {
      width: calc(83.33333% - 1.66667rem); }
    .grid-margin-x > .landscapebr-11 {
      width: calc(91.66667% - 1.66667rem); }
    .grid-margin-x > .landscapebr-12 {
      width: calc(100% - 1.66667rem); } }
  @media print, screen and (min-width: 64.0625em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.66667rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.66667rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.66667rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.66667rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.66667rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.66667rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.66667rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.66667rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.66667rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.66667rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.66667rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.66667rem); } }
  @media screen and (min-width: 90.125em) {
    .grid-margin-x > .xlarge-auto {
      width: auto; }
    .grid-margin-x > .xlarge-shrink {
      width: auto; }
    .grid-margin-x > .xlarge-1 {
      width: calc(8.33333% - 1.66667rem); }
    .grid-margin-x > .xlarge-2 {
      width: calc(16.66667% - 1.66667rem); }
    .grid-margin-x > .xlarge-3 {
      width: calc(25% - 1.66667rem); }
    .grid-margin-x > .xlarge-4 {
      width: calc(33.33333% - 1.66667rem); }
    .grid-margin-x > .xlarge-5 {
      width: calc(41.66667% - 1.66667rem); }
    .grid-margin-x > .xlarge-6 {
      width: calc(50% - 1.66667rem); }
    .grid-margin-x > .xlarge-7 {
      width: calc(58.33333% - 1.66667rem); }
    .grid-margin-x > .xlarge-8 {
      width: calc(66.66667% - 1.66667rem); }
    .grid-margin-x > .xlarge-9 {
      width: calc(75% - 1.66667rem); }
    .grid-margin-x > .xlarge-10 {
      width: calc(83.33333% - 1.66667rem); }
    .grid-margin-x > .xlarge-11 {
      width: calc(91.66667% - 1.66667rem); }
    .grid-margin-x > .xlarge-12 {
      width: calc(100% - 1.66667rem); } }
  @media screen and (min-width: 120em) {
    .grid-margin-x > .xxlarge-auto {
      width: auto; }
    .grid-margin-x > .xxlarge-shrink {
      width: auto; }
    .grid-margin-x > .xxlarge-1 {
      width: calc(8.33333% - 1.66667rem); }
    .grid-margin-x > .xxlarge-2 {
      width: calc(16.66667% - 1.66667rem); }
    .grid-margin-x > .xxlarge-3 {
      width: calc(25% - 1.66667rem); }
    .grid-margin-x > .xxlarge-4 {
      width: calc(33.33333% - 1.66667rem); }
    .grid-margin-x > .xxlarge-5 {
      width: calc(41.66667% - 1.66667rem); }
    .grid-margin-x > .xxlarge-6 {
      width: calc(50% - 1.66667rem); }
    .grid-margin-x > .xxlarge-7 {
      width: calc(58.33333% - 1.66667rem); }
    .grid-margin-x > .xxlarge-8 {
      width: calc(66.66667% - 1.66667rem); }
    .grid-margin-x > .xxlarge-9 {
      width: calc(75% - 1.66667rem); }
    .grid-margin-x > .xxlarge-10 {
      width: calc(83.33333% - 1.66667rem); }
    .grid-margin-x > .xxlarge-11 {
      width: calc(91.66667% - 1.66667rem); }
    .grid-margin-x > .xxlarge-12 {
      width: calc(100% - 1.66667rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.55556rem;
  margin-left: -0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.83333rem;
      margin-left: -0.83333rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.55556rem;
  margin-left: -0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.83333rem;
      margin-left: -0.83333rem; } }

.grid-padding-x > .cell {
  padding-right: 0.55556rem;
  padding-left: 0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-padding-x > .cell {
      padding-right: 0.83333rem;
      padding-left: 0.83333rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 37.5em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-up-1 > .cell {
    width: 100%; }
  .landscapebr-up-2 > .cell {
    width: 50%; }
  .landscapebr-up-3 > .cell {
    width: 33.33333%; }
  .landscapebr-up-4 > .cell {
    width: 25%; }
  .landscapebr-up-5 > .cell {
    width: 20%; }
  .landscapebr-up-6 > .cell {
    width: 16.66667%; }
  .landscapebr-up-7 > .cell {
    width: 14.28571%; }
  .landscapebr-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64.0625em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

@media screen and (min-width: 90.125em) {
  .xlarge-up-1 > .cell {
    width: 100%; }
  .xlarge-up-2 > .cell {
    width: 50%; }
  .xlarge-up-3 > .cell {
    width: 33.33333%; }
  .xlarge-up-4 > .cell {
    width: 25%; }
  .xlarge-up-5 > .cell {
    width: 20%; }
  .xlarge-up-6 > .cell {
    width: 16.66667%; }
  .xlarge-up-7 > .cell {
    width: 14.28571%; }
  .xlarge-up-8 > .cell {
    width: 12.5%; } }

@media screen and (min-width: 120em) {
  .xxlarge-up-1 > .cell {
    width: 100%; }
  .xxlarge-up-2 > .cell {
    width: 50%; }
  .xxlarge-up-3 > .cell {
    width: 33.33333%; }
  .xxlarge-up-4 > .cell {
    width: 25%; }
  .xxlarge-up-5 > .cell {
    width: 20%; }
  .xxlarge-up-6 > .cell {
    width: 16.66667%; }
  .xxlarge-up-7 > .cell {
    width: 14.28571%; }
  .xxlarge-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.11111rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.11111rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.11111rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.11111rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.11111rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.11111rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.11111rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.11111rem); }

@media print, screen and (min-width: 37.5em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.11111rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.11111rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.11111rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.11111rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.11111rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.11111rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.11111rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.11111rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.66667rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.66667rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.66667rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.66667rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.66667rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.66667rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.66667rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.66667rem); } }

@media print, screen and (min-width: 48em) {
  .grid-margin-x.landscapebr-up-1 > .cell {
    width: calc(100% - 1.66667rem); }
  .grid-margin-x.landscapebr-up-2 > .cell {
    width: calc(50% - 1.66667rem); }
  .grid-margin-x.landscapebr-up-3 > .cell {
    width: calc(33.33333% - 1.66667rem); }
  .grid-margin-x.landscapebr-up-4 > .cell {
    width: calc(25% - 1.66667rem); }
  .grid-margin-x.landscapebr-up-5 > .cell {
    width: calc(20% - 1.66667rem); }
  .grid-margin-x.landscapebr-up-6 > .cell {
    width: calc(16.66667% - 1.66667rem); }
  .grid-margin-x.landscapebr-up-7 > .cell {
    width: calc(14.28571% - 1.66667rem); }
  .grid-margin-x.landscapebr-up-8 > .cell {
    width: calc(12.5% - 1.66667rem); } }

@media print, screen and (min-width: 64.0625em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.66667rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.66667rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.66667rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.66667rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.66667rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.66667rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.66667rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.66667rem); } }

@media screen and (min-width: 90.125em) {
  .grid-margin-x.xlarge-up-1 > .cell {
    width: calc(100% - 1.66667rem); }
  .grid-margin-x.xlarge-up-2 > .cell {
    width: calc(50% - 1.66667rem); }
  .grid-margin-x.xlarge-up-3 > .cell {
    width: calc(33.33333% - 1.66667rem); }
  .grid-margin-x.xlarge-up-4 > .cell {
    width: calc(25% - 1.66667rem); }
  .grid-margin-x.xlarge-up-5 > .cell {
    width: calc(20% - 1.66667rem); }
  .grid-margin-x.xlarge-up-6 > .cell {
    width: calc(16.66667% - 1.66667rem); }
  .grid-margin-x.xlarge-up-7 > .cell {
    width: calc(14.28571% - 1.66667rem); }
  .grid-margin-x.xlarge-up-8 > .cell {
    width: calc(12.5% - 1.66667rem); } }

@media screen and (min-width: 120em) {
  .grid-margin-x.xxlarge-up-1 > .cell {
    width: calc(100% - 1.66667rem); }
  .grid-margin-x.xxlarge-up-2 > .cell {
    width: calc(50% - 1.66667rem); }
  .grid-margin-x.xxlarge-up-3 > .cell {
    width: calc(33.33333% - 1.66667rem); }
  .grid-margin-x.xxlarge-up-4 > .cell {
    width: calc(25% - 1.66667rem); }
  .grid-margin-x.xxlarge-up-5 > .cell {
    width: calc(20% - 1.66667rem); }
  .grid-margin-x.xxlarge-up-6 > .cell {
    width: calc(16.66667% - 1.66667rem); }
  .grid-margin-x.xxlarge-up-7 > .cell {
    width: calc(14.28571% - 1.66667rem); }
  .grid-margin-x.xxlarge-up-8 > .cell {
    width: calc(12.5% - 1.66667rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 37.5em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 48em) {
    .small-margin-collapse > .landscapebr-1 {
      width: 8.33333%; }
    .small-margin-collapse > .landscapebr-2 {
      width: 16.66667%; }
    .small-margin-collapse > .landscapebr-3 {
      width: 25%; }
    .small-margin-collapse > .landscapebr-4 {
      width: 33.33333%; }
    .small-margin-collapse > .landscapebr-5 {
      width: 41.66667%; }
    .small-margin-collapse > .landscapebr-6 {
      width: 50%; }
    .small-margin-collapse > .landscapebr-7 {
      width: 58.33333%; }
    .small-margin-collapse > .landscapebr-8 {
      width: 66.66667%; }
    .small-margin-collapse > .landscapebr-9 {
      width: 75%; }
    .small-margin-collapse > .landscapebr-10 {
      width: 83.33333%; }
    .small-margin-collapse > .landscapebr-11 {
      width: 91.66667%; }
    .small-margin-collapse > .landscapebr-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64.0625em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }
  @media screen and (min-width: 90.125em) {
    .small-margin-collapse > .xlarge-1 {
      width: 8.33333%; }
    .small-margin-collapse > .xlarge-2 {
      width: 16.66667%; }
    .small-margin-collapse > .xlarge-3 {
      width: 25%; }
    .small-margin-collapse > .xlarge-4 {
      width: 33.33333%; }
    .small-margin-collapse > .xlarge-5 {
      width: 41.66667%; }
    .small-margin-collapse > .xlarge-6 {
      width: 50%; }
    .small-margin-collapse > .xlarge-7 {
      width: 58.33333%; }
    .small-margin-collapse > .xlarge-8 {
      width: 66.66667%; }
    .small-margin-collapse > .xlarge-9 {
      width: 75%; }
    .small-margin-collapse > .xlarge-10 {
      width: 83.33333%; }
    .small-margin-collapse > .xlarge-11 {
      width: 91.66667%; }
    .small-margin-collapse > .xlarge-12 {
      width: 100%; } }
  @media screen and (min-width: 120em) {
    .small-margin-collapse > .xxlarge-1 {
      width: 8.33333%; }
    .small-margin-collapse > .xxlarge-2 {
      width: 16.66667%; }
    .small-margin-collapse > .xxlarge-3 {
      width: 25%; }
    .small-margin-collapse > .xxlarge-4 {
      width: 33.33333%; }
    .small-margin-collapse > .xxlarge-5 {
      width: 41.66667%; }
    .small-margin-collapse > .xxlarge-6 {
      width: 50%; }
    .small-margin-collapse > .xxlarge-7 {
      width: 58.33333%; }
    .small-margin-collapse > .xxlarge-8 {
      width: 66.66667%; }
    .small-margin-collapse > .xxlarge-9 {
      width: 75%; }
    .small-margin-collapse > .xxlarge-10 {
      width: 83.33333%; }
    .small-margin-collapse > .xxlarge-11 {
      width: 91.66667%; }
    .small-margin-collapse > .xxlarge-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 37.5em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 37.5em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 37.5em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse > .landscapebr-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .landscapebr-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .landscapebr-3 {
    width: 25%; }
  .medium-margin-collapse > .landscapebr-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .landscapebr-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .landscapebr-6 {
    width: 50%; }
  .medium-margin-collapse > .landscapebr-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .landscapebr-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .landscapebr-9 {
    width: 75%; }
  .medium-margin-collapse > .landscapebr-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .landscapebr-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .landscapebr-12 {
    width: 100%; } }

@media print, screen and (min-width: 64.0625em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 90.125em) {
  .medium-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .xlarge-3 {
    width: 25%; }
  .medium-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .xlarge-6 {
    width: 50%; }
  .medium-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .xlarge-9 {
    width: 75%; }
  .medium-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .medium-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .medium-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .medium-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .medium-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media print, screen and (min-width: 37.5em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .landscapebr-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-margin-collapse > .small-1 {
    width: 8.33333%; }
  .landscapebr-margin-collapse > .small-2 {
    width: 16.66667%; }
  .landscapebr-margin-collapse > .small-3 {
    width: 25%; }
  .landscapebr-margin-collapse > .small-4 {
    width: 33.33333%; }
  .landscapebr-margin-collapse > .small-5 {
    width: 41.66667%; }
  .landscapebr-margin-collapse > .small-6 {
    width: 50%; }
  .landscapebr-margin-collapse > .small-7 {
    width: 58.33333%; }
  .landscapebr-margin-collapse > .small-8 {
    width: 66.66667%; }
  .landscapebr-margin-collapse > .small-9 {
    width: 75%; }
  .landscapebr-margin-collapse > .small-10 {
    width: 83.33333%; }
  .landscapebr-margin-collapse > .small-11 {
    width: 91.66667%; }
  .landscapebr-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .landscapebr-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .landscapebr-margin-collapse > .medium-3 {
    width: 25%; }
  .landscapebr-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .landscapebr-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .landscapebr-margin-collapse > .medium-6 {
    width: 50%; }
  .landscapebr-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .landscapebr-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .landscapebr-margin-collapse > .medium-9 {
    width: 75%; }
  .landscapebr-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .landscapebr-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .landscapebr-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-margin-collapse > .landscapebr-1 {
    width: 8.33333%; }
  .landscapebr-margin-collapse > .landscapebr-2 {
    width: 16.66667%; }
  .landscapebr-margin-collapse > .landscapebr-3 {
    width: 25%; }
  .landscapebr-margin-collapse > .landscapebr-4 {
    width: 33.33333%; }
  .landscapebr-margin-collapse > .landscapebr-5 {
    width: 41.66667%; }
  .landscapebr-margin-collapse > .landscapebr-6 {
    width: 50%; }
  .landscapebr-margin-collapse > .landscapebr-7 {
    width: 58.33333%; }
  .landscapebr-margin-collapse > .landscapebr-8 {
    width: 66.66667%; }
  .landscapebr-margin-collapse > .landscapebr-9 {
    width: 75%; }
  .landscapebr-margin-collapse > .landscapebr-10 {
    width: 83.33333%; }
  .landscapebr-margin-collapse > .landscapebr-11 {
    width: 91.66667%; }
  .landscapebr-margin-collapse > .landscapebr-12 {
    width: 100%; } }

@media print, screen and (min-width: 64.0625em) {
  .landscapebr-margin-collapse > .large-1 {
    width: 8.33333%; }
  .landscapebr-margin-collapse > .large-2 {
    width: 16.66667%; }
  .landscapebr-margin-collapse > .large-3 {
    width: 25%; }
  .landscapebr-margin-collapse > .large-4 {
    width: 33.33333%; }
  .landscapebr-margin-collapse > .large-5 {
    width: 41.66667%; }
  .landscapebr-margin-collapse > .large-6 {
    width: 50%; }
  .landscapebr-margin-collapse > .large-7 {
    width: 58.33333%; }
  .landscapebr-margin-collapse > .large-8 {
    width: 66.66667%; }
  .landscapebr-margin-collapse > .large-9 {
    width: 75%; }
  .landscapebr-margin-collapse > .large-10 {
    width: 83.33333%; }
  .landscapebr-margin-collapse > .large-11 {
    width: 91.66667%; }
  .landscapebr-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 90.125em) {
  .landscapebr-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .landscapebr-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .landscapebr-margin-collapse > .xlarge-3 {
    width: 25%; }
  .landscapebr-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .landscapebr-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .landscapebr-margin-collapse > .xlarge-6 {
    width: 50%; }
  .landscapebr-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .landscapebr-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .landscapebr-margin-collapse > .xlarge-9 {
    width: 75%; }
  .landscapebr-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .landscapebr-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .landscapebr-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .landscapebr-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .landscapebr-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .landscapebr-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .landscapebr-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .landscapebr-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .landscapebr-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .landscapebr-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .landscapebr-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .landscapebr-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .landscapebr-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .landscapebr-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .landscapebr-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .landscapebr-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64.0625em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64.0625em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64.0625em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64.0625em) {
  .large-margin-collapse > .landscapebr-1 {
    width: 8.33333%; }
  .large-margin-collapse > .landscapebr-2 {
    width: 16.66667%; }
  .large-margin-collapse > .landscapebr-3 {
    width: 25%; }
  .large-margin-collapse > .landscapebr-4 {
    width: 33.33333%; }
  .large-margin-collapse > .landscapebr-5 {
    width: 41.66667%; }
  .large-margin-collapse > .landscapebr-6 {
    width: 50%; }
  .large-margin-collapse > .landscapebr-7 {
    width: 58.33333%; }
  .large-margin-collapse > .landscapebr-8 {
    width: 66.66667%; }
  .large-margin-collapse > .landscapebr-9 {
    width: 75%; }
  .large-margin-collapse > .landscapebr-10 {
    width: 83.33333%; }
  .large-margin-collapse > .landscapebr-11 {
    width: 91.66667%; }
  .large-margin-collapse > .landscapebr-12 {
    width: 100%; } }

@media print, screen and (min-width: 64.0625em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 90.125em) {
  .large-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .large-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .large-margin-collapse > .xlarge-3 {
    width: 25%; }
  .large-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .large-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .large-margin-collapse > .xlarge-6 {
    width: 50%; }
  .large-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .large-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .large-margin-collapse > .xlarge-9 {
    width: 75%; }
  .large-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .large-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .large-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .large-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .large-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .large-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .large-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .large-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .large-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .large-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .large-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .large-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .large-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .large-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .large-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media print, screen and (min-width: 64.0625em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 90.125em) {
  .xlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 90.125em) {
  .xlarge-margin-collapse > .small-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .small-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .small-3 {
    width: 25%; }
  .xlarge-margin-collapse > .small-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .small-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .small-6 {
    width: 50%; }
  .xlarge-margin-collapse > .small-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .small-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .small-9 {
    width: 75%; }
  .xlarge-margin-collapse > .small-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .small-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .small-12 {
    width: 100%; } }

@media screen and (min-width: 90.125em) {
  .xlarge-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .medium-3 {
    width: 25%; }
  .xlarge-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .medium-6 {
    width: 50%; }
  .xlarge-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .medium-9 {
    width: 75%; }
  .xlarge-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .medium-12 {
    width: 100%; } }

@media screen and (min-width: 90.125em) {
  .xlarge-margin-collapse > .landscapebr-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .landscapebr-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .landscapebr-3 {
    width: 25%; }
  .xlarge-margin-collapse > .landscapebr-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .landscapebr-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .landscapebr-6 {
    width: 50%; }
  .xlarge-margin-collapse > .landscapebr-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .landscapebr-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .landscapebr-9 {
    width: 75%; }
  .xlarge-margin-collapse > .landscapebr-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .landscapebr-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .landscapebr-12 {
    width: 100%; } }

@media screen and (min-width: 90.125em) {
  .xlarge-margin-collapse > .large-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .large-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .large-3 {
    width: 25%; }
  .xlarge-margin-collapse > .large-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .large-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .large-6 {
    width: 50%; }
  .xlarge-margin-collapse > .large-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .large-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .large-9 {
    width: 75%; }
  .xlarge-margin-collapse > .large-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .large-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 90.125em) {
  .xlarge-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xlarge-3 {
    width: 25%; }
  .xlarge-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xlarge-6 {
    width: 50%; }
  .xlarge-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xlarge-9 {
    width: 75%; }
  .xlarge-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .xlarge-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .xlarge-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .xlarge-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .xlarge-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media screen and (min-width: 90.125em) {
  .xlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 120em) {
  .xxlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 120em) {
  .xxlarge-margin-collapse > .small-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .small-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .small-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .small-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .small-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .small-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .small-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .small-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .small-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .small-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .small-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .small-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .xxlarge-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .medium-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .medium-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .medium-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .medium-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .xxlarge-margin-collapse > .landscapebr-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .landscapebr-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .landscapebr-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .landscapebr-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .landscapebr-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .landscapebr-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .landscapebr-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .landscapebr-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .landscapebr-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .landscapebr-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .landscapebr-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .landscapebr-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .xxlarge-margin-collapse > .large-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .large-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .large-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .large-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .large-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .large-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .large-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .large-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .large-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .large-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .large-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .xxlarge-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xlarge-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xlarge-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xlarge-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .xxlarge-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media screen and (min-width: 120em) {
  .xxlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.55556rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.55556rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.55556rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.55556rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.55556rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.55556rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.55556rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.55556rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.55556rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.55556rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.55556rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.55556rem); }

@media print, screen and (min-width: 37.5em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.83333rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.83333rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.83333rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.83333rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.83333rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.83333rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.83333rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.83333rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.83333rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.83333rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.83333rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.83333rem); } }

@media print, screen and (min-width: 48em) {
  .landscapebr-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .landscapebr-offset-0 {
    margin-left: calc(0% + 0.83333rem); }
  .landscapebr-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .landscapebr-offset-1 {
    margin-left: calc(8.33333% + 0.83333rem); }
  .landscapebr-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .landscapebr-offset-2 {
    margin-left: calc(16.66667% + 0.83333rem); }
  .landscapebr-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .landscapebr-offset-3 {
    margin-left: calc(25% + 0.83333rem); }
  .landscapebr-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .landscapebr-offset-4 {
    margin-left: calc(33.33333% + 0.83333rem); }
  .landscapebr-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .landscapebr-offset-5 {
    margin-left: calc(41.66667% + 0.83333rem); }
  .landscapebr-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .landscapebr-offset-6 {
    margin-left: calc(50% + 0.83333rem); }
  .landscapebr-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .landscapebr-offset-7 {
    margin-left: calc(58.33333% + 0.83333rem); }
  .landscapebr-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .landscapebr-offset-8 {
    margin-left: calc(66.66667% + 0.83333rem); }
  .landscapebr-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .landscapebr-offset-9 {
    margin-left: calc(75% + 0.83333rem); }
  .landscapebr-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .landscapebr-offset-10 {
    margin-left: calc(83.33333% + 0.83333rem); }
  .landscapebr-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .landscapebr-offset-11 {
    margin-left: calc(91.66667% + 0.83333rem); } }

@media print, screen and (min-width: 64.0625em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.83333rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.83333rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.83333rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.83333rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.83333rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.83333rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.83333rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.83333rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.83333rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.83333rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.83333rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.83333rem); } }

@media screen and (min-width: 90.125em) {
  .xlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xlarge-offset-0 {
    margin-left: calc(0% + 0.83333rem); }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xlarge-offset-1 {
    margin-left: calc(8.33333% + 0.83333rem); }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xlarge-offset-2 {
    margin-left: calc(16.66667% + 0.83333rem); }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .xlarge-offset-3 {
    margin-left: calc(25% + 0.83333rem); }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xlarge-offset-4 {
    margin-left: calc(33.33333% + 0.83333rem); }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xlarge-offset-5 {
    margin-left: calc(41.66667% + 0.83333rem); }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .xlarge-offset-6 {
    margin-left: calc(50% + 0.83333rem); }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xlarge-offset-7 {
    margin-left: calc(58.33333% + 0.83333rem); }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xlarge-offset-8 {
    margin-left: calc(66.66667% + 0.83333rem); }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .xlarge-offset-9 {
    margin-left: calc(75% + 0.83333rem); }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xlarge-offset-10 {
    margin-left: calc(83.33333% + 0.83333rem); }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xlarge-offset-11 {
    margin-left: calc(91.66667% + 0.83333rem); } }

@media screen and (min-width: 120em) {
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xxlarge-offset-0 {
    margin-left: calc(0% + 0.83333rem); }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xxlarge-offset-1 {
    margin-left: calc(8.33333% + 0.83333rem); }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xxlarge-offset-2 {
    margin-left: calc(16.66667% + 0.83333rem); }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .xxlarge-offset-3 {
    margin-left: calc(25% + 0.83333rem); }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xxlarge-offset-4 {
    margin-left: calc(33.33333% + 0.83333rem); }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xxlarge-offset-5 {
    margin-left: calc(41.66667% + 0.83333rem); }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .xxlarge-offset-6 {
    margin-left: calc(50% + 0.83333rem); }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xxlarge-offset-7 {
    margin-left: calc(58.33333% + 0.83333rem); }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xxlarge-offset-8 {
    margin-left: calc(66.66667% + 0.83333rem); }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .xxlarge-offset-9 {
    margin-left: calc(75% + 0.83333rem); }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xxlarge-offset-10 {
    margin-left: calc(83.33333% + 0.83333rem); }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xxlarge-offset-11 {
    margin-left: calc(91.66667% + 0.83333rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 37.5em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 48em) {
    .grid-y > .landscapebr-shrink, .grid-y > .landscapebr-full, .grid-y > .landscapebr-1, .grid-y > .landscapebr-2, .grid-y > .landscapebr-3, .grid-y > .landscapebr-4, .grid-y > .landscapebr-5, .grid-y > .landscapebr-6, .grid-y > .landscapebr-7, .grid-y > .landscapebr-8, .grid-y > .landscapebr-9, .grid-y > .landscapebr-10, .grid-y > .landscapebr-11, .grid-y > .landscapebr-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64.0625em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  @media screen and (min-width: 90.125em) {
    .grid-y > .xlarge-shrink, .grid-y > .xlarge-full, .grid-y > .xlarge-1, .grid-y > .xlarge-2, .grid-y > .xlarge-3, .grid-y > .xlarge-4, .grid-y > .xlarge-5, .grid-y > .xlarge-6, .grid-y > .xlarge-7, .grid-y > .xlarge-8, .grid-y > .xlarge-9, .grid-y > .xlarge-10, .grid-y > .xlarge-11, .grid-y > .xlarge-12 {
      flex-basis: auto; } }
  @media screen and (min-width: 120em) {
    .grid-y > .xxlarge-shrink, .grid-y > .xxlarge-full, .grid-y > .xxlarge-1, .grid-y > .xxlarge-2, .grid-y > .xxlarge-3, .grid-y > .xxlarge-4, .grid-y > .xxlarge-5, .grid-y > .xxlarge-6, .grid-y > .xxlarge-7, .grid-y > .xxlarge-8, .grid-y > .xxlarge-9, .grid-y > .xxlarge-10, .grid-y > .xxlarge-11, .grid-y > .xxlarge-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 37.5em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 48em) {
    .grid-y > .landscapebr-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .landscapebr-shrink {
      height: auto; }
    .grid-y > .landscapebr-1 {
      height: 8.33333%; }
    .grid-y > .landscapebr-2 {
      height: 16.66667%; }
    .grid-y > .landscapebr-3 {
      height: 25%; }
    .grid-y > .landscapebr-4 {
      height: 33.33333%; }
    .grid-y > .landscapebr-5 {
      height: 41.66667%; }
    .grid-y > .landscapebr-6 {
      height: 50%; }
    .grid-y > .landscapebr-7 {
      height: 58.33333%; }
    .grid-y > .landscapebr-8 {
      height: 66.66667%; }
    .grid-y > .landscapebr-9 {
      height: 75%; }
    .grid-y > .landscapebr-10 {
      height: 83.33333%; }
    .grid-y > .landscapebr-11 {
      height: 91.66667%; }
    .grid-y > .landscapebr-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64.0625em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }
  @media screen and (min-width: 90.125em) {
    .grid-y > .xlarge-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .xlarge-shrink {
      height: auto; }
    .grid-y > .xlarge-1 {
      height: 8.33333%; }
    .grid-y > .xlarge-2 {
      height: 16.66667%; }
    .grid-y > .xlarge-3 {
      height: 25%; }
    .grid-y > .xlarge-4 {
      height: 33.33333%; }
    .grid-y > .xlarge-5 {
      height: 41.66667%; }
    .grid-y > .xlarge-6 {
      height: 50%; }
    .grid-y > .xlarge-7 {
      height: 58.33333%; }
    .grid-y > .xlarge-8 {
      height: 66.66667%; }
    .grid-y > .xlarge-9 {
      height: 75%; }
    .grid-y > .xlarge-10 {
      height: 83.33333%; }
    .grid-y > .xlarge-11 {
      height: 91.66667%; }
    .grid-y > .xlarge-12 {
      height: 100%; } }
  @media screen and (min-width: 120em) {
    .grid-y > .xxlarge-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .xxlarge-shrink {
      height: auto; }
    .grid-y > .xxlarge-1 {
      height: 8.33333%; }
    .grid-y > .xxlarge-2 {
      height: 16.66667%; }
    .grid-y > .xxlarge-3 {
      height: 25%; }
    .grid-y > .xxlarge-4 {
      height: 33.33333%; }
    .grid-y > .xxlarge-5 {
      height: 41.66667%; }
    .grid-y > .xxlarge-6 {
      height: 50%; }
    .grid-y > .xxlarge-7 {
      height: 58.33333%; }
    .grid-y > .xxlarge-8 {
      height: 66.66667%; }
    .grid-y > .xxlarge-9 {
      height: 75%; }
    .grid-y > .xxlarge-10 {
      height: 83.33333%; }
    .grid-y > .xxlarge-11 {
      height: 91.66667%; }
    .grid-y > .xxlarge-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.55556rem;
  margin-bottom: -0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.83333rem;
      margin-bottom: -0.83333rem; } }

.grid-padding-y > .cell {
  padding-top: 0.55556rem;
  padding-bottom: 0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-padding-y > .cell {
      padding-top: 0.83333rem;
      padding-bottom: 0.83333rem; } }

.grid-margin-y {
  margin-top: -0.55556rem;
  margin-bottom: -0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-margin-y {
      margin-top: -0.83333rem;
      margin-bottom: -0.83333rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.11111rem);
    margin-top: 0.55556rem;
    margin-bottom: 0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.66667rem);
      margin-top: 0.83333rem;
      margin-bottom: 0.83333rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.11111rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.11111rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.11111rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.11111rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.11111rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.11111rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.11111rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.11111rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.11111rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.11111rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.11111rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.11111rem); }
  @media print, screen and (min-width: 37.5em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.66667rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.66667rem); } }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .landscapebr-auto {
      height: auto; }
    .grid-margin-y > .landscapebr-shrink {
      height: auto; }
    .grid-margin-y > .landscapebr-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .landscapebr-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .landscapebr-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .landscapebr-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .landscapebr-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .landscapebr-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .landscapebr-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .landscapebr-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .landscapebr-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .landscapebr-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .landscapebr-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .landscapebr-12 {
      height: calc(100% - 1.66667rem); } }
  @media print, screen and (min-width: 64.0625em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.66667rem); } }
  @media screen and (min-width: 90.125em) {
    .grid-margin-y > .xlarge-auto {
      height: auto; }
    .grid-margin-y > .xlarge-shrink {
      height: auto; }
    .grid-margin-y > .xlarge-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .xlarge-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .xlarge-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .xlarge-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .xlarge-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .xlarge-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .xlarge-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .xlarge-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .xlarge-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .xlarge-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .xlarge-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .xlarge-12 {
      height: calc(100% - 1.66667rem); } }
  @media screen and (min-width: 120em) {
    .grid-margin-y > .xxlarge-auto {
      height: auto; }
    .grid-margin-y > .xxlarge-shrink {
      height: auto; }
    .grid-margin-y > .xxlarge-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .xxlarge-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .xxlarge-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .xxlarge-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .xxlarge-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .xxlarge-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .xxlarge-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .xxlarge-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .xxlarge-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .xxlarge-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .xxlarge-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .xxlarge-12 {
      height: calc(100% - 1.66667rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 37.5em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .landscapebr-grid-frame {
    width: 100%; }
  .landscapebr-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .landscapebr-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .landscapebr-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .landscapebr-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64.0625em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media screen and (min-width: 90.125em) {
  .xlarge-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .xlarge-grid-frame {
    width: 100%; }
  .xlarge-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .xlarge-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .xlarge-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .xlarge-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media screen and (min-width: 120em) {
  .xxlarge-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .xxlarge-grid-frame {
    width: 100%; }
  .xxlarge-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .xxlarge-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .xxlarge-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .xxlarge-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 37.5em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 48em) {
  .grid-y.landscapebr-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64.0625em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media screen and (min-width: 90.125em) {
  .grid-y.xlarge-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media screen and (min-width: 120em) {
  .grid-y.xxlarge-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 37.5em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 48em) {
  .cell .grid-y.landscapebr-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64.0625em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

@media screen and (min-width: 90.125em) {
  .cell .grid-y.xlarge-grid-frame {
    height: 100%; } }

@media screen and (min-width: 120em) {
  .cell .grid-y.xxlarge-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.55556rem;
  margin-bottom: -0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-margin-y {
      margin-top: -0.83333rem;
      margin-bottom: -0.83333rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.11111rem);
    margin-top: 0.55556rem;
    margin-bottom: 0.55556rem; }
  @media print, screen and (min-width: 37.5em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.66667rem);
      margin-top: 0.83333rem;
      margin-bottom: 0.83333rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.11111rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.11111rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.11111rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.11111rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.11111rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.11111rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.11111rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.11111rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.11111rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.11111rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.11111rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.11111rem); }
  @media print, screen and (min-width: 37.5em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.66667rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.66667rem); } }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .landscapebr-auto {
      height: auto; }
    .grid-margin-y > .landscapebr-shrink {
      height: auto; }
    .grid-margin-y > .landscapebr-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .landscapebr-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .landscapebr-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .landscapebr-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .landscapebr-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .landscapebr-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .landscapebr-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .landscapebr-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .landscapebr-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .landscapebr-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .landscapebr-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .landscapebr-12 {
      height: calc(100% - 1.66667rem); } }
  @media print, screen and (min-width: 64.0625em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.66667rem); } }
  @media screen and (min-width: 90.125em) {
    .grid-margin-y > .xlarge-auto {
      height: auto; }
    .grid-margin-y > .xlarge-shrink {
      height: auto; }
    .grid-margin-y > .xlarge-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .xlarge-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .xlarge-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .xlarge-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .xlarge-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .xlarge-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .xlarge-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .xlarge-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .xlarge-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .xlarge-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .xlarge-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .xlarge-12 {
      height: calc(100% - 1.66667rem); } }
  @media screen and (min-width: 120em) {
    .grid-margin-y > .xxlarge-auto {
      height: auto; }
    .grid-margin-y > .xxlarge-shrink {
      height: auto; }
    .grid-margin-y > .xxlarge-1 {
      height: calc(8.33333% - 1.66667rem); }
    .grid-margin-y > .xxlarge-2 {
      height: calc(16.66667% - 1.66667rem); }
    .grid-margin-y > .xxlarge-3 {
      height: calc(25% - 1.66667rem); }
    .grid-margin-y > .xxlarge-4 {
      height: calc(33.33333% - 1.66667rem); }
    .grid-margin-y > .xxlarge-5 {
      height: calc(41.66667% - 1.66667rem); }
    .grid-margin-y > .xxlarge-6 {
      height: calc(50% - 1.66667rem); }
    .grid-margin-y > .xxlarge-7 {
      height: calc(58.33333% - 1.66667rem); }
    .grid-margin-y > .xxlarge-8 {
      height: calc(66.66667% - 1.66667rem); }
    .grid-margin-y > .xxlarge-9 {
      height: calc(75% - 1.66667rem); }
    .grid-margin-y > .xxlarge-10 {
      height: calc(83.33333% - 1.66667rem); }
    .grid-margin-y > .xxlarge-11 {
      height: calc(91.66667% - 1.66667rem); }
    .grid-margin-y > .xxlarge-12 {
      height: calc(100% - 1.66667rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.11111rem); }
  @media print, screen and (min-width: 37.5em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.66667rem); } }
  @media print, screen and (min-width: 48em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.66667rem); } }
  @media print, screen and (min-width: 64.0625em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.66667rem); } }
  @media screen and (min-width: 90.125em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.66667rem); } }
  @media screen and (min-width: 120em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.66667rem); } }

@media print, screen and (min-width: 37.5em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.66667rem); } }

@media print, screen and (min-width: 48em) {
  .grid-margin-y.landscapebr-grid-frame {
    height: calc(100vh + 1.66667rem); } }

@media print, screen and (min-width: 64.0625em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.66667rem); } }

@media screen and (min-width: 90.125em) {
  .grid-margin-y.xlarge-grid-frame {
    height: calc(100vh + 1.66667rem); } }

@media screen and (min-width: 120em) {
  .grid-margin-y.xxlarge-grid-frame {
    height: calc(100vh + 1.66667rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "myriad-pro", sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.33333rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.11111rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.05556rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 0.94444rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 0.88889rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 37.5em) {
  h1, .h1 {
    font-size: 2.66667rem; }
  h2, .h2 {
    font-size: 2.22222rem; }
  h3, .h3 {
    font-size: 1.72222rem; }
  h4, .h4 {
    font-size: 1.38889rem; }
  h5, .h5 {
    font-size: 1.11111rem; }
  h6, .h6 {
    font-size: 0.88889rem; } }

a {
  line-height: inherit;
  color: #1779ba;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1468a0; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 1160px;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 37.5em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-text-left {
    text-align: left; }
  .landscapebr-text-right {
    text-align: right; }
  .landscapebr-text-center {
    text-align: center; }
  .landscapebr-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64.0625em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 90.125em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 120em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button, .text__block a.button, .intro__block a.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #FF6200;
  color: #fff; }
  [data-whatinput='mouse'] .button, [data-whatinput='mouse'] .text__block a.button, .text__block [data-whatinput='mouse'] a.button, [data-whatinput='mouse'] .intro__block a.button, .intro__block [data-whatinput='mouse'] a.button {
    outline: 0; }
  .button:hover, .text__block a.button:hover, .intro__block a.button:hover, .button:focus, .text__block a.button:focus, .intro__block a.button:focus {
    background-color: #d95300;
    color: #fff; }
  .button.tiny, .text__block a.tiny.button, .intro__block a.tiny.button {
    font-size: 0.6rem; }
  .button.small, .text__block a.small.button, .intro__block a.small.button {
    font-size: 0.75rem; }
  .button.large, .text__block a.large.button, .intro__block a.large.button {
    font-size: 1.25rem; }
  .button.expanded, .text__block a.expanded.button, .intro__block a.expanded.button {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary, .text__block a.primary.button, .intro__block a.primary.button {
    background-color: #1779ba;
    color: #fff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #126195;
      color: #fff; }
  .button.secondary, .text__block a.secondary.button, .intro__block a.secondary.button {
    background-color: #767676;
    color: #fff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fff; }
  .button.success, .text__block a.success.button, .intro__block a.success.button {
    background-color: green;
    color: #fff; }
    .button.success:hover, .button.success:focus {
      background-color: #006600;
      color: #fff; }
  .button.warning, .text__block a.warning.button, .intro__block a.warning.button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert, .text__block a.alert.button, .intro__block a.alert.button {
    background-color: #cc4b37;
    color: #fff; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fff; }
  .button.disabled, .text__block a.disabled.button, .intro__block a.disabled.button, .button[disabled], .text__block a[disabled].button, .intro__block a[disabled].button {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .text__block a.disabled.button, .intro__block a.disabled.button, .button.disabled:hover, .button.disabled:focus, .button[disabled], .text__block a[disabled].button, .intro__block a[disabled].button, .button[disabled]:hover, .button[disabled]:focus {
      background-color: #FF6200;
      color: #fff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #1779ba;
        color: #fff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fff; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: green;
        color: #fff; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fff; }
  .button.hollow, .text__block a.hollow.button, .intro__block a.hollow.button {
    border: 1px solid #FF6200;
    color: #FF6200; }
    .button.hollow, .text__block a.hollow.button, .intro__block a.hollow.button, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #803100;
      color: #803100; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #FF6200;
        color: #FF6200; }
    .button.hollow.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid green;
      color: green; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #004000;
        color: #004000; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid green;
          color: green; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear, .text__block a.clear.button, .intro__block a.clear.button {
    border: 1px solid #FF6200;
    color: #FF6200; }
    .button.clear, .text__block a.clear.button, .intro__block a.clear.button, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #803100;
      color: #803100; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #FF6200;
        color: #FF6200; }
    .button.clear, .text__block a.clear.button, .intro__block a.clear.button, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #1779ba;
      color: #1779ba; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #0c3d5d;
        color: #0c3d5d; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #1779ba;
          color: #1779ba; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid green;
      color: green; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #004000;
        color: #004000; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid green;
          color: green; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #FF6200; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #1779ba; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: green; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.56944rem;
  margin: 0 0 1rem;
  padding: 0.625rem 1.25rem;
  border: 1px solid rgba(74, 85, 107, 0.11);
  border-radius: 20px;
  background-color: #fefefe;
  box-shadow: none;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.375rem;
  color: #4A556B;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid rgba(74, 85, 107, 0.11);
    background-color: #fefefe;
    box-shadow: none;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: rgba(74, 85, 107, 0.5); }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 20px; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.55556rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 20px 0 0 20px; }
  .input-group > :last-child > * {
    border-radius: 0 20px 20px 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.16667rem;
    padding: 0 0.1875rem; }

select {
  height: 2.56944rem;
  margin: 0 0 1rem;
  padding: 0.625rem 1.25rem;
  appearance: none;
  border: 1px solid rgba(74, 85, 107, 0.11);
  border-radius: 20px;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.375rem;
  color: #4A556B;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid rgba(74, 85, 107, 0.11);
    background-color: #fefefe;
    box-shadow: none;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #1779ba; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.accordion-menu li {
  width: 100%; }

.accordion-menu a {
  padding: 0.7rem 1rem; }

.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem; }

.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem; }

.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0; }

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative; }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 1rem; }

.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem; }

.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem; }

.accordion-menu .is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.is-accordion-submenu-parent {
  position: relative; }

.has-submenu-toggle > a {
  margin-right: 40px; }

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px; }
  .submenu-toggle::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    top: 0;
    bottom: 0;
    margin: auto; }

.submenu-toggle[aria-expanded='true']::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%; }

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #1779ba;
  color: #fefefe; }
  .badge.primary {
    background: #1779ba;
    color: #fefefe; }
  .badge.secondary {
    background: #767676;
    color: #fefefe; }
  .badge.success {
    background: green;
    color: #fefefe; }
  .badge.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .badge.alert {
    background: #cc4b37;
    color: #fefefe; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #0a0a0a;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #1779ba; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button, .button-group .text__block a.button, .text__block .button-group a.button, .button-group .intro__block a.button, .intro__block .button-group a.button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #1779ba;
    color: #fff; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #126195;
      color: #fff; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #fff; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #fff; }
  .button-group.success .button {
    background-color: green;
    color: #fff; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #006600;
      color: #fff; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #fff; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #fff; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 37.5em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64.0625em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 37.4375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: white;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #d7ecfa;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #0a0a0a; }
  .callout.success {
    background-color: #c6ffc6;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button,
  .menu .text__block a.button, .text__block
  .menu a.button,
  .menu .intro__block a.button, .intro__block
  .menu a.button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 37.5em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 48em) {
    .menu.landscapebr-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.landscapebr-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.landscapebr-expanded li {
      flex: 1 1 0px; }
    .menu.landscapebr-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64.0625em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  @media screen and (min-width: 90.125em) {
    .menu.xlarge-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xlarge-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xlarge-expanded li {
      flex: 1 1 0px; }
    .menu.xlarge-simple li {
      flex: 1 1 0px; } }
  @media screen and (min-width: 120em) {
    .menu.xxlarge-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xxlarge-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xxlarge-expanded li {
      flex: 1 1 0px; }
    .menu.xxlarge-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu .active > a {
    background: #1779ba;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #1779ba transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #1779ba; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #1779ba transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #1779ba; }

@media print, screen and (min-width: 37.5em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 48em) {
  .dropdown.menu.landscapebr-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.landscapebr-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.landscapebr-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.landscapebr-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.landscapebr-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.landscapebr-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.landscapebr-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.landscapebr-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.landscapebr-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.landscapebr-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media print, screen and (min-width: 64.0625em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media screen and (min-width: 90.125em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xlarge-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.xlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

@media screen and (min-width: 120em) {
  .dropdown.menu.xxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #1779ba transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.xxlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xxlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #1779ba transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #1779ba; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label, .header__teaser .teaser__title .title__label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #1779ba;
  color: #fefefe; }
  .label.primary, .header__teaser .teaser__title .primary.title__label {
    background: #1779ba;
    color: #fefefe; }
  .label.secondary, .header__teaser .teaser__title .secondary.title__label {
    background: #767676;
    color: #fefefe; }
  .label.success, .header__teaser .teaser__title .success.title__label {
    background: green;
    color: #fefefe; }
  .label.warning, .header__teaser .teaser__title .warning.title__label {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert, .header__teaser .teaser__title .alert.title__label {
    background: #cc4b37;
    color: #fefefe; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 37.4375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 37.4375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 37.5em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 48em) {
  .position-left.reveal-for-landscapebr {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-landscapebr .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-landscapebr {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-landscapebr ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-landscapebr {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-landscapebr .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-landscapebr {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-landscapebr ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-landscapebr {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-landscapebr .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-landscapebr {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-landscapebr ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-landscapebr {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-landscapebr .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-landscapebr {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-landscapebr ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64.0625em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media screen and (min-width: 90.125em) {
  .position-left.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-xlarge ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-xlarge ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-xlarge ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-xlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-xlarge .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-xlarge {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-xlarge ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media screen and (min-width: 120em) {
  .position-left.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-xxlarge ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-xxlarge ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-xxlarge ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-xxlarge {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-xxlarge .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-xxlarge {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-xxlarge ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 37.5em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 48em) {
  .off-canvas.in-canvas-for-landscapebr {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-landscapebr.position-left, .off-canvas.in-canvas-for-landscapebr.position-right, .off-canvas.in-canvas-for-landscapebr.position-top, .off-canvas.in-canvas-for-landscapebr.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-landscapebr .close-button {
      display: none; } }

@media print, screen and (min-width: 64.0625em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

@media screen and (min-width: 90.125em) {
  .off-canvas.in-canvas-for-xlarge {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-xlarge.position-left, .off-canvas.in-canvas-for-xlarge.position-right, .off-canvas.in-canvas-for-xlarge.position-top, .off-canvas.in-canvas-for-xlarge.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-xlarge .close-button {
      display: none; } }

@media screen and (min-width: 120em) {
  .off-canvas.in-canvas-for-xxlarge {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-xxlarge.position-left, .off-canvas.in-canvas-for-xxlarge.position-right, .off-canvas.in-canvas-for-xxlarge.position-top, .off-canvas.in-canvas-for-xxlarge.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-xxlarge .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fff; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fff; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #8a8a8a; }
    .orbit-bullets button.is-active {
      background-color: #8a8a8a; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 37.5em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #1779ba;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #1779ba; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: green; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #1779ba; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #1779ba;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #14679e; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 37.5em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 37.5em) {
    .reveal {
      width: 600px;
      max-width: 1160px; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 37.5em) {
    .reveal.tiny {
      width: 30%;
      max-width: 1160px; } }
  @media print, screen and (min-width: 37.5em) {
    .reveal.small {
      width: 50%;
      max-width: 1160px; } }
  @media print, screen and (min-width: 37.5em) {
    .reveal.large {
      width: 90%;
      max-width: 1160px; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 37.4375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.77778rem;
  font-weight: bold;
  color: #fff;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #1779ba; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.55556rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.66667rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 0.88889rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f8f8f8;
    color: #0a0a0a; }
  table tfoot {
    background: #f1f1f1;
    color: #0a0a0a; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 47.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #1779ba; }
  .tabs.primary > li > a {
    color: #fff; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #1673b1; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #1779ba; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #1468a0; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #1779ba; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(23, 121, 186, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 37.5em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 47.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 64em) {
    .top-bar.stacked-for-landscapebr {
      flex-wrap: wrap; }
      .top-bar.stacked-for-landscapebr .top-bar-left,
      .top-bar.stacked-for-landscapebr .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 90.0625em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 119.9375em) {
    .top-bar.stacked-for-xlarge {
      flex-wrap: wrap; }
      .top-bar.stacked-for-xlarge .top-bar-left,
      .top-bar.stacked-for-xlarge .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  .top-bar.stacked-for-xxlarge {
    flex-wrap: wrap; }
    .top-bar.stacked-for-xxlarge .top-bar-left,
    .top-bar.stacked-for-xxlarge .top-bar-right {
      flex: 0 0 100%;
      max-width: 100%; }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 37.4375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 37.5em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 37.5em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 37.4375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 37.5em) and (max-width: 47.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 37.4375em), screen and (min-width: 48em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-landscapebr {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .show-for-landscapebr {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 64em) {
  .hide-for-landscapebr-only {
    display: none !important; } }

@media screen and (max-width: 47.9375em), screen and (min-width: 64.0625em) {
  .show-for-landscapebr-only {
    display: none !important; } }

@media print, screen and (min-width: 64.0625em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 64em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64.0625em) and (max-width: 90.0625em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 64em), screen and (min-width: 90.125em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 90.125em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 90.0625em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 90.125em) and (max-width: 119.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 90.0625em), screen and (min-width: 120em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 120em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 119.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 120em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 119.9375em) {
  .show-for-xxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .help__avator::before, .header__preheader::before, .image__block::before, .clearfix::after, .help__avator::after, .header__preheader::after, .image__block::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after, .help__avator::after, .header__preheader::after, .image__block::after {
  clear: both; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 37.5em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-order-1 {
    order: 1; }
  .landscapebr-order-2 {
    order: 2; }
  .landscapebr-order-3 {
    order: 3; }
  .landscapebr-order-4 {
    order: 4; }
  .landscapebr-order-5 {
    order: 5; }
  .landscapebr-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64.0625em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 90.125em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 120em) {
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 37.5em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 48em) {
  .landscapebr-flex-container {
    display: flex; }
  .landscapebr-flex-child-auto {
    flex: 1 1 auto; }
  .landscapebr-flex-child-grow {
    flex: 1 0 auto; }
  .landscapebr-flex-child-shrink {
    flex: 0 1 auto; }
  .landscapebr-flex-dir-row {
    flex-direction: row; }
  .landscapebr-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .landscapebr-flex-dir-column {
    flex-direction: column; }
  .landscapebr-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64.0625em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 90.125em) {
  .xlarge-flex-container {
    display: flex; }
  .xlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xlarge-flex-dir-row {
    flex-direction: row; }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xlarge-flex-dir-column {
    flex-direction: column; }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 120em) {
  .xxlarge-flex-container {
    display: flex; }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xxlarge-flex-dir-row {
    flex-direction: row; }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xxlarge-flex-dir-column {
    flex-direction: column; }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

* {
  outline: none; }

html {
  min-height: 100%;
  width: 100%; }

body {
  background: #fff;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  body .hamburger-close {
    display: none; }

.js body.page--loading:after {
  content: "";
  position: fixed;
  background: #fff;
  height: 100%;
  width: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.js body.page--loading.page--loaded:after {
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0; }

.margin-bottom {
  margin-bottom: 40px; }

@media screen and (max-width: 48.0625em) {
  [data-paroller-factor], .paroller {
    transform: none !important; } }

.horizontal-divider {
  border-bottom: 1px dashed #B7B7B7;
  width: 100%; }

.vertical-divider {
  border-right: 1px dashed #B7B7B7;
  height: 100%; }

.mpf-video {
  position: relative; }
  .mpf-video img {
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
  .mpf-video:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(74, 85, 107, 0.2);
    z-index: 1;
    left: 0;
    top: 0;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
  .mpf-video:before {
    height: 68px;
    width: 68px;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    line-height: 68px;
    text-align: center;
    color: #fff;
    font-size: 20px; }
  .mpf-video:hover:after {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    transform: scale(1.05); }
  .mpf-video:hover img {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    transform: scale(1.05); }

.color-brand-primary {
  color: #FF6200; }

.color-brand-secundary {
  color: #4A556B; }

.highlight {
  color: #FF6200; }

.bg-image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }
  .bg-image.greyoval {
    background-image: url("../images/gymna-400/background-greyoval-gts.png");
    background-size: contain !important;
    background-position: -50px 10% !important; }
    @media screen and (min-width: 64.0625em) {
      .bg-image.greyoval {
        background-position: 80% 10% !important; } }
    @media screen and (max-width: 37.4375em) {
      .bg-image.greyoval {
        display: none; } }
  .bg-image.gymna400vip {
    background-image: url("../images/gymna-400/background-splash-gymna400vip.png");
    background-size: contain !important;
    background-position: 20px 30px !important;
    background-size: 850px !important; }
    @media screen and (min-width: 64.0625em) {
      .bg-image.gymna400vip {
        background-position: 20px 30px !important;
        background-size: 850px !important; } }
  .bg-image.splash {
    background-image: url("../images/gymna-400/background-splash-easy-to-use.png");
    background-size: contain !important;
    background-position: top right !important;
    background-size: 850px !important; }
    @media screen and (min-width: 64.0625em) {
      .bg-image.splash {
        background-position: 20px right !important;
        background-size: 850px !important; } }

.column-bg-image-left .bg-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

@media screen and (max-width: 37.4375em) {
  .column-bg-image-left {
    position: relative;
    padding-bottom: 40px;
    margin-top: 20px; }
    .column-bg-image-left .bg-image {
      width: 100%; } }

.column-bg-image-right .bg-image {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  height: 100%;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

@media screen and (max-width: 37.4375em) {
  .column-bg-image-right {
    position: relative;
    padding-bottom: 40px;
    margin-top: 20px; }
    .column-bg-image-right .bg-image {
      width: 100%; } }

.bg-grey {
  background: rgba(74, 85, 107, 0.3); }

.bg--shadow-bottom {
  box-shadow: 0 -7px 164px 0 rgba(10, 10, 10, 0.05); }

.bg--shadow-top {
  box-shadow: 0 13px 164px 0 rgba(10, 10, 10, 0.1); }

.bg-brand-primary {
  background: #FF6200;
  color: #fff; }

.bg-brand-secundary {
  background: #4A556B;
  color: #fff; }

.bg-white {
  background: #fff; }
  @media screen and (max-width: 47.9375em) {
    .bg-white-medium {
      background: #fff; } }
  @media screen and (max-width: 37.4375em) {
    .bg-white-small {
      background: #fff; } }

.wow {
  visibility: hidden;
  /*
  @include breakpoint(medium down){
    visibility: visible !important;
    animation: none !important;
    transform: none !important;
    transition: none !important;
    animation-name: none !important;
    animation-duration: 0s !important;
    animation-fill-mode: both  !important;
    animation-delay: 0s !important;
    
  }
  */ }

.clickable {
  cursor: pointer; }

.link, .text__block a.link, .intro__block a.link {
  font-weight: 600;
  color: #FF6200; }
  .link:focus, .text__block a.link:focus, .intro__block a.link:focus, .link:hover, .text__block a.link:hover, .intro__block a.link:hover {
    color: #d95300; }

.button, .text__block a.button, .intro__block a.button {
  border-radius: 26px;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-family: "myriad-pro", sans-serif;
  font-weight: 600;
  vertical-align: middle;
  font-size: 1rem;
  padding: 15px 27px;
  margin: 0 0 1rem 0;
  background: #FF6200;
  color: #fff; }
  @media screen and (max-width: 37.4375em) {
    .button, .text__block a.button, .intro__block a.button {
      font-weight: 500;
      padding: 10px 15px;
      font-size: 0.83333rem; } }
  .button + .link, .text__block a.button + .link, .intro__block a.button + .link, .text__block .button + a.link, .intro__block .button + a.link {
    margin: 0 0 1rem 30px;
    display: inline-block;
    vertical-align: middle; }
  .button:focus, .text__block a.button:focus, .intro__block a.button:focus, .button:hover, .text__block a.button:hover, .intro__block a.button:hover {
    background: #d95300;
    color: #fff; }
  .button.outline, .text__block a.outline.button, .intro__block a.outline.button {
    border: 1px solid #fff;
    background-color: #4A556B; }
    .button.outline:focus, .button.outline:hover {
      background-color: #FF6200;
      border: 1px solid #FF6200; }
  .button.reverse, .text__block a.reverse.button, .intro__block a.reverse.button {
    background-color: #FF6200; }
    .button.reverse:focus, .button.reverse:hover {
      background-color: #4A556B; }
    .button.reverse.outline {
      border: 1px solid transparent; }
      .button.reverse.outline:focus, .button.reverse.outline:hover {
        border: 1px solid #fff; }
  .button.brand-grey, .text__block a.brand-grey.button, .intro__block a.brand-grey.button {
    background: rgba(74, 85, 107, 0.2);
    color: #fff;
    font-size: 1rem;
    border-radius: 25px; }
    .button.brand-grey:focus, .button.brand-grey:hover {
      background: rgba(0, 0, 0, 0.2); }
  .button.large, .text__block a.large.button, .intro__block a.large.button {
    font-size: 1.11111rem;
    padding: 15px 27px;
    border-radius: 26px; }
    @media screen and (max-width: 37.4375em) {
      .button.large, .text__block a.large.button, .intro__block a.large.button {
        font-weight: 600;
        vertical-align: middle;
        font-size: 1rem;
        padding: 15px 27px; } }

a {
  color: #2199e8;
  text-decoration: none;
  line-height: inherit;
  cursor: pointer; }

p {
  margin-bottom: 30px; }

@keyframes spinIn {
  from {
    opacity: 0; }
  to {
    opacity: .4; } }

@keyframes spinScale {
  from { }
  to {
    transform: scale(2); } }

.spin {
  position: absolute;
  z-index: 1;
  width: 1345px;
  height: 1264px; }
  .spin .line {
    opacity: 0; }
  .slide2 .spin {
    bottom: -340px;
    right: -210px; }
  .slide3 .spin {
    bottom: -340px;
    left: -530px; }
  .spin.is-visible .line {
    opacity: .4; }
  .spin.is-animated {
    animation-duration: 5s;
    animation-fill-mode: both;
    animation-name: spinScale; }
    .spin.is-animated .line {
      animation-duration: .5s;
      animation-fill-mode: both;
      animation-name: spinIn; }
  .spin .line {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    display: block;
    background: url("../images/spin.svg") no-repeat center center;
    background-size: 100%;
    opacity: 0; }
    .spin .line.line--1 {
      width: 100%;
      height: 100%;
      animation-duration: .30s; }
    .spin .line.line--2 {
      width: 90%;
      height: 90%;
      animation-duration: .25s; }
    .spin .line.line--3 {
      width: 75%;
      height: 75%;
      animation-duration: .20s; }
    .spin .line.line--4 {
      width: 60%;
      height: 60%;
      animation-duration: .15s; }
    .spin .line.line--5 {
      width: 55%;
      height: 55%;
      animation-duration: .1s; }
    .spin .line.line--6 {
      width: 50%;
      height: 50%;
      animation-duration: .095s; }
    .spin .line.line--7 {
      width: 45%;
      height: 45%;
      animation-duration: .085s; }
    .spin .line.line--8 {
      width: 40%;
      height: 40%;
      animation-duration: .075s; }
    .spin .line.line--9 {
      width: 30%;
      height: 30%;
      animation-duration: .065s; }
    .spin .line.line--10 {
      width: 25%;
      height: 25%;
      animation-duration: .055s; }
    .spin .line.line--11 {
      width: 20%;
      height: 20%;
      animation-duration: .045s; }
    .spin .line.line--12 {
      width: 15%;
      height: 15%;
      animation-duration: .035s; }
    .spin .line.line--13 {
      width: 12%;
      height: 12%;
      animation-duration: .025s; }
    .spin .line.line--14 {
      width: 10%;
      height: 10%;
      animation-duration: .015s; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.z-index-1 {
  z-index: 1; }

.z-index-2 {
  z-index: 2; }

.focuspoint {
  position: absolute;
  /*Any position but static should work*/
  overflow: hidden;
  height: 100%;
  width: 100%; }
  .focuspoint img {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    display: block;
    /* fill and maintain aspect ratio */
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    max-height: none;
    max-width: none; }

.dummy {
  visibility: hidden;
  position: relative;
  z-index: 0; }

.parallax {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0; }

@keyframes explosion {
  20% {
    transform: scale3d(0.9, 0.9, 0.9) translateY(10%); }
  50%,
  55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    transform: scale3d(20, 20, 20); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .parallax .explosion {
    animation-name: explosion;
    animation-duration: 0.75s;
    animation-fill-mode: both; }
  .parallax .dummy {
    position: relative;
    z-index: 0; }
  .parallax .layer-1, .parallax .layer-2, .parallax .layer-3 {
    position: absolute;
    z-index: 0;
    left: -100px;
    right: -100px;
    top: -100px;
    bottom: -100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom; }
    @media screen and (max-width: 64.0625em) {
      .parallax .layer-1, .parallax .layer-2, .parallax .layer-3 {
        bottom: 0 !important;
        transform: none !important; } }
    .parallax .layer-1.loading, .parallax .layer-2.loading, .parallax .layer-3.loading {
      transition: transform 1s ease-in-out; }
    .parallax .layer-1 img.is-hide, .parallax .layer-2 img.is-hide, .parallax .layer-3 img.is-hide {
      opacity: 0; }
      .parallax .layer-1 img.is-hide.fadeIn, .parallax .layer-2 img.is-hide.fadeIn, .parallax .layer-3 img.is-hide.fadeIn {
        animation-name: fadeIn;
        animation-duration: 0.75s;
        animation-fill-mode: both; }
  .parallax .layer-1 {
    z-index: 1; }
  .parallax .layer-2 {
    z-index: 2; }
    .parallax .layer-2 img {
      position: absolute;
      bottom: 0; }
    .page--landing-400 .parallax .layer-2 img {
      position: absolute;
      right: -15%;
      bottom: auto; }
    .slide3 .parallax .layer-2 img {
      position: absolute;
      left: 100px;
      bottom: 0;
      max-width: 40%; }
    .slide2 .parallax .layer-2 img {
      position: absolute;
      right: 100px;
      bottom: 0;
      max-width: 40%; }
  .parallax .layer-3 {
    z-index: 3; }
    .parallax .layer-3 img {
      position: absolute;
      bottom: 0; }
    .page--landing-400 .parallax .layer-3 img {
      position: absolute;
      right: 100px;
      transform: translateY(-50%);
      top: 50%;
      margin-top: 50px;
      bottom: auto; }

.popup {
  position: relative;
  background: #FFF;
  padding: 75px 65px;
  width: auto;
  max-width: 590px;
  margin: 20px auto;
  box-shadow: 0 22px 53px 0 rgba(0, 0, 0, 0.1); }
  .popup p {
    font-size: 1rem;
    line-height: 1.44444rem; }
  .popup .mfp-close {
    top: 22px;
    right: 22px;
    font-size: 38px; }

#site_wrapper {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed;
  overflow: hidden;
  max-width: 100%;
  position: relative; }

#main {
  height: auto; }

header, #main, footer {
  display: table-row;
  height: 1px;
  width: 100%;
  overflow: hidden; }

header {
  position: relative;
  z-index: 0; }

.label, .header__teaser .teaser__title .title__label {
  background: #FF6200;
  color: #fff; }
  .label b, .header__teaser .teaser__title .title__label b {
    font-weight: 600; }

.shadow {
  box-shadow: -10px 10px 56px 0 rgba(0, 0, 0, 0.15); }

.bordered {
  border: 6px solid #fff; }

.section-padding {
  padding-top: 40px; }
  .section-padding.bg-image, .section-padding.bg--shadow-bottom, .section-padding.bg--shadow-top {
    padding-bottom: 40px; }
  @media print, screen and (min-width: 37.5em) {
    .section-padding {
      padding-top: 80px; }
      .section-padding.bg-image, .section-padding.bg--shadow-bottom, .section-padding.bg--shadow-top {
        padding-bottom: 80px; } }
  @media print, screen and (min-width: 64.0625em) {
    .section-padding {
      padding-top: 125px; }
      .page--landing-400 .section-padding {
        padding-top: 80px; }
      .section-padding.bg-image, .section-padding.bg--shadow-bottom, .section-padding.bg--shadow-top {
        padding-bottom: 125px; }
        .page--landing-400 .section-padding.bg-image, .page--landing-400 .section-padding.bg--shadow-bottom, .page--landing-400 .section-padding.bg--shadow-top {
          padding-bottom: 80px; } }
  .section-padding + .bg--shadow-top {
    margin-top: 40px; }
    @media print, screen and (min-width: 37.5em) {
      .section-padding + .bg--shadow-top {
        margin-top: 80px; } }
    @media print, screen and (min-width: 64.0625em) {
      .section-padding + .bg--shadow-top {
        margin-top: 125px; } }
  @media screen and (max-width: 37.4375em) {
    .section-padding.gymna400vip {
      padding-bottom: 0 !important; } }

.no-gutter {
  margin-left: 0;
  margin-right: 0; }
  .no-gutter > .column, .no-gutter > .columns,
  .no-gutter > .columns {
    padding-left: 0;
    padding-right: 0; }

.context__practice {
  background: #fff;
  position: relative;
  z-index: 1; }

.context__treatments .image__block .image__overlay {
  float: right;
  margin-top: -55px;
  width: auto;
  margin-right: 20px; }

.context__treatments .help__block {
  margin: 10px 0 0 0; }

.form__message .error {
  color: #cc4b37; }

.form__message .success {
  color: green; }

.button__expertise {
  width: 100px;
  margin-bottom: 30px;
  display: block;
  position: relative;
  z-index: 2; }

.rasterlines {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 0px;
  transform: translateX(-50%); }

@keyframes chart10 {
  from {
    stroke-dashoffset: 366; }
  to {
    stroke-dashoffset: 329.4; } }

@keyframes chart75 {
  from {
    stroke-dashoffset: 366; }
  to {
    stroke-dashoffset: 91.5; } }

@keyframes chart80 {
  from {
    stroke-dashoffset: 366; }
  to {
    stroke-dashoffset: 73.2; } }

.chart {
  position: relative;
  display: inline-block;
  color: #999;
  font-size: 20px;
  text-align: center; }
  .chart .outer {
    fill: transparent;
    stroke: #FF6200;
    stroke-width: 10;
    stroke-dasharray: 366;
    transition: stroke-dashoffset 1s;
    animation-play-state: running; }
  .chart figcaption {
    width: 126px;
    height: 126px;
    border: 10px solid #F6F6F6;
    border-radius: 100%;
    display: block;
    position: relative; }
    .chart figcaption .caption {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: center;
      transform: translateX(-50%) translateY(-50%); }
      .chart figcaption .caption .counter {
        color: #FF6200;
        font-size: 1.77778rem;
        line-height: 0.88889rem;
        font-weight: 700; }
      .chart figcaption .caption .title {
        display: block;
        font-size: 0.72222rem;
        line-height: 0.72222rem;
        color: #4A556B; }
  .chart[data-percent='10'] .outer {
    stroke-dashoffset: 329.4;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0; }
  .chart[data-percent='10'].is-active .outer {
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
    animation: chart10 2s; }
  .chart[data-percent='75'] .outer {
    stroke-dashoffset: 91.5;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0; }
  .chart[data-percent='75'].is-active .outer {
    animation: chart75 2s;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1; }
  .chart[data-percent='80'] .outer {
    stroke-dashoffset: 73.2;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    opacity: 0; }
  .chart[data-percent='80'].is-active .outer {
    animation: chart80 2s;
    filter: alpha(opacity=100);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1; }
  .chart svg {
    position: absolute;
    top: 0;
    left: 0; }
  .chart:hover .outer {
    stroke-dashoffset: 366 !important;
    animation-play-state: paused; }

h1, .h1 {
  font-family: "myriad-pro", sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.22222rem;
  margin-bottom: 1.11111rem; }
  @media screen and (max-width: 47.9375em) {
    h1, .h1 {
      font-size: 2rem;
      font-weight: bold;
      line-height: 2.33333rem; } }
  @media screen and (max-width: 37.4375em) {
    h1, .h1 {
      font-size: 1.44444rem;
      font-weight: bold;
      line-height: 1.77778rem; } }
  .page--landing-400 h1, .page--landing-400 .h1 {
    margin-bottom: 0.27778rem; }

h2, .h2 {
  font-family: "myriad-pro", sans-serif;
  font-size: 2.11111rem;
  line-height: 2.61111rem;
  font-weight: 700;
  margin-bottom: 22px; }
  h2.label, .header__teaser .teaser__title h2.title__label, .h2.label, .header__teaser .teaser__title .h2.title__label {
    font-size: 2.11111rem;
    line-height: 2.61111rem;
    margin-bottom: 20px; }
  h2.font-italic, .h2.font-italic {
    margin-bottom: 40px; }

h3, .h3 {
  font-family: "myriad-pro", sans-serif;
  font-size: 1.11111rem;
  line-height: 1.44444rem;
  font-weight: 700;
  margin-bottom: 10px; }
  h3.label, .header__teaser .teaser__title h3.title__label, .h3.label, .header__teaser .teaser__title .h3.title__label {
    font-size: 1.11111rem;
    line-height: 1.44444rem;
    margin-bottom: 20px; }

h5, .h5 {
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 20px; }

.font-italic {
  font-style: italic;
  font-weight: 500;
  color: #FF6200; }

.ticker {
  line-height: 35px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 26px;
  padding: 0 17px;
  color: #4A556B;
  font-size: 0.77778rem;
  display: inline-block;
  vertical-align: middle;
  display: none !important; }
  .ticker a {
    color: inherit; }
  .ticker .ticker__repeat {
    display: inline-block;
    vertical-align: middle; }
  .ticker .ticker__message {
    display: inline-block;
    vertical-align: middle; }
  .ticker .icon-refresh {
    font-size: 0.55556rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px; }
  .ticker .icon-help {
    font-size: 0.83333rem;
    display: inline-block;
    vertical-align: middle;
    margin-left: 35px; }
  @media screen and (max-width: 50.9375em) {
    .ticker {
      display: none; } }

.product__item {
  width: 100%;
  display: inline-block;
  margin-bottom: 50px; }
  .product__item:last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 48em) {
    .product__item {
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d; }
      .product__item img {
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); } }
  @media screen and (min-width: 37.5em) {
    .product__item {
      max-width: 340px; } }
  .product__item .product__image {
    text-align: center;
    margin-bottom: 50px;
    position: relative; }
    .product__item .product__image .logo {
      position: absolute;
      right: 0;
      bottom: 10px;
      top: auto;
      transform: none; }
    @media screen and (min-width: 48em) {
      .page--landing-400 .product__item .product__image {
        margin-top: 50px; } }
  .product__item .product__description {
    text-align: left;
    position: relative;
    padding-bottom: 50px; }
    .product__item .product__description h3, .product__item .product__description .h3 {
      font-size: 1.77778rem;
      line-height: 2.11111rem;
      font-weight: 600; }
      .product__item .product__description h3 b, .product__item .product__description h3 strong, .product__item .product__description .h3 b, .product__item .product__description .h3 strong {
        font-weight: 700; }
    .product__item .product__description p {
      font-size: 0.88889rem;
      line-height: 1.44444rem;
      margin-bottom: 1em; }
    .product__item .product__description ul, .product__item .product__description ol {
      font-size: 1rem;
      line-height: 1.66667rem; }
    .product__item .product__description .text__block, .product__item .product__description .intro__block {
      padding-bottom: 20px;
      color: #4A556B; }
      @media print, screen and (min-width: 64.0625em) {
        .product__item .product__description .text__block, .product__item .product__description .intro__block {
          padding-bottom: 70px; }
          .page--landing-400 .product__item .product__description .text__block, .page--landing-400 .product__item .product__description .intro__block {
            padding-bottom: 40px; } }
    .product__item .product__description .product__footer {
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      white-space: nowrap; }
      .product__item .product__description .product__footer .button,
      .product__item .product__description .product__footer .link {
        margin-bottom: 0; }
      .product__item .product__description .product__footer.text-left {
        text-align: left; }

.help__avator {
  position: fixed;
  bottom: 20px;
  padding-right: 20px;
  z-index: 900;
  max-width: 320px;
  right: 0;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  transform: translateX(100%);
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  .help__avator.is-active-static, .help__avator.is-active {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0); }
  .help__avator .avator__text {
    border-radius: 6px;
    background-color: #fff;
    padding: 25px 36px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    margin-bottom: 25px;
    display: none; }
    .help__avator .avator__text.is-active.is-animated {
      animation-name: jackInTheBox;
      animation-duration: 1s;
      animation-fill-mode: both;
      display: block; }
    .help__avator .avator__text:after {
      width: 0;
      height: 0;
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-top: 13px solid #fff;
      position: absolute;
      right: 37px;
      bottom: -13px;
      content: ""; }
      @media screen and (max-width: 37.4375em) {
        .help__avator .avator__text:after {
          right: 27px; } }
    .help__avator .avator__text p {
      margin-bottom: 17px; }
    .help__avator .avator__text a {
      text-decoration: underline;
      font-weight: 600;
      color: #FF6200; }
      .help__avator .avator__text a:focus, .help__avator .avator__text a:hover {
        text-decoration: none; }
  .help__avator .avator__image {
    float: right;
    cursor: pointer; }
    .help__avator .avator__image img {
      border-radius: 100%;
      width: 100px;
      height: 100px;
      overflow: hidden;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15); }
      @media screen and (max-width: 37.4375em) {
        .help__avator .avator__image img {
          width: 80px;
          height: 80px; } }

.header__preheader {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; }
  @media screen and (max-width: 48.0625em) {
    .header__preheader {
      position: fixed;
      background: #fff;
      box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1); } }
  .header__preheader .logo {
    float: left;
    z-index: 2;
    margin: 20px;
    margin-right: 0; }
    .header__preheader .logo img {
      height: 36px;
      width: 130px;
      display: block; }
    @media screen and (min-width: 75em) {
      .header__preheader .logo {
        margin: 50px 0 50px  60px; } }
  .header__preheader .cta__block {
    float: right;
    margin: 20px;
    margin-left: 0; }
    @media screen and (min-width: 75em) {
      .header__preheader .cta__block {
        margin: 50px 60px 50px  0; } }
    .header__preheader .cta__block .button {
      vertical-align: middle;
      margin: 0 0 0 25px; }
      @media screen and (max-width: 48.0625em) {
        .header__preheader .cta__block .button {
          margin: 0; } }

.header__teaser {
  position: relative;
  z-index: 1;
  overflow: hidden; }
  .header__teaser .close-btn {
    position: fixed;
    z-index: 100;
    height: 30px;
    width: 30px;
    line-height: 30px;
    display: none;
    color: #333;
    text-decoration: none;
    text-align: center;
    opacity: .65;
    padding: 0;
    font-style: normal;
    font-size: 35px;
    font-family: Arial,Baskerville,monospace; }
    .header__teaser .close-btn.is-visible {
      display: block; }
  .header__teaser.teaser--large {
    height: 90vh;
    max-height: 800px; }
    @media screen and (max-width: 85.375em) {
      .header__teaser.teaser--large {
        height: 70vh;
        min-height: 700px; } }
    @media screen and (max-width: 64.0625em) {
      .header__teaser.teaser--large {
        min-height: 500px; } }
    @media screen and (max-width: 48.0625em) {
      .header__teaser.teaser--large {
        height: auto;
        min-height: 1px; } }
  .header__teaser .teaser__mobile {
    position: relative;
    display: none; }
    @media screen and (max-width: 48.0625em) {
      .header__teaser .teaser__mobile {
        display: block; } }
    .header__teaser .teaser__mobile .mobile__bg {
      background: url("../images/parallax/shockmaster-layer-1.jpg") no-repeat center center;
      background-size: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1; }
      .page--landing-400 .header__teaser .teaser__mobile .mobile__bg {
        background: url("../images/parallax/gymna-400/gymna-layer-1.jpg") no-repeat center center;
        background-size: cover; }
        .page--landing-400 .header__teaser .teaser__mobile .mobile__bg:before {
          content: "";
          background: url("../images/parallax/gymna-400/gymna-layer-2.png");
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: 2; }
    .header__teaser .teaser__mobile .mobile__image {
      position: relative;
      z-index: 3;
      padding-top: 110px; }
      .page--landing-400 .header__teaser .teaser__mobile .mobile__image {
        padding-bottom: 30px; }
      .header__teaser .teaser__mobile .mobile__image img {
        margin: 0 auto;
        max-width: 90%; }
        .page--landing-400 .header__teaser .teaser__mobile .mobile__image img {
          max-width: 50%; }
  .header__teaser .teaser__image {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background: url("../images/parallax/shockmaster-white.png") repeat-x bottom; }
    @media screen and (max-width: 48.0625em) {
      .header__teaser .teaser__image {
        display: none !important; } }
    @media screen and (max-width: 48.0625em) {
      .header__teaser .teaser__image {
        position: relative;
        height: 80vh;
        min-height: 350px; } }
    @media screen and (max-width: 37.4375em) {
      .header__teaser .teaser__image {
        min-height: 1px; } }
  .header__teaser .teaser__pointers {
    position: absolute;
    display: block;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    margin-left: -720px; }
    @media screen and (max-width: 90em) {
      .header__teaser .teaser__pointers {
        max-width: 100%;
        margin-left: 0;
        left: 0; } }
    .header__teaser .teaser__pointers .pointer {
      display: none;
      position: absolute;
      z-index: 5; }
      .header__teaser .teaser__pointers .pointer.animated.is-animated {
        animation-duration: 1.2s;
        animation-fill-mode: both;
        animation-name: bounceIn;
        display: block; }
        .header__teaser .teaser__pointers .pointer.animated.is-animated.pointer--1 {
          animation-delay: 1s; }
        .header__teaser .teaser__pointers .pointer.animated.is-animated.pointer--2 {
          animation-delay: 1.5s; }
        .header__teaser .teaser__pointers .pointer.animated.is-animated.pointer--3 {
          animation-delay: 2s; }
        .header__teaser .teaser__pointers .pointer.animated.is-animated.pointer--4 {
          animation-delay: 2.5s; }

@keyframes pointerPulse {
  from {
    transform: scale3d(1, 1, 1); }
  50% {
    transform: scale3d(1.3, 1.3, 1.3); }
  to {
    transform: scale3d(1, 1, 1); } }
      .header__teaser .teaser__pointers .pointer .pointer__bull {
        height: 36px;
        width: 36px;
        margin-left: -18px;
        margin-top: -18px;
        background: #fff;
        border-radius: 100%;
        display: block;
        overflow: hidden;
        border: 5px solid #FF6200;
        cursor: pointer; }
        .header__teaser .teaser__pointers .pointer .pointer__bull.animate-pulse-refresh {
          animation-duration: .8s;
          animation-fill-mode: both;
          animation-iteration-count: 1;
          animation-name: pointerPulse; }
        .header__teaser .teaser__pointers .pointer .pointer__bull:hover {
          animation-duration: .8s !important;
          animation-fill-mode: both !important;
          animation-name: pointerPulse !important;
          animation-iteration-count: infinite !important; }
        .header__teaser .teaser__pointers .pointer .pointer__bull.fadeOut {
          animation-delay: 0s !important;
          animation-duration: .5s !important;
          animation-fill-mode: both !important;
          animation-name: fadeOut !important;
          animation-iteration-count: 1 !important; }
      .header__teaser .teaser__pointers .pointer.pointer--1 {
        left: 50%;
        top: 90%; }
        .header__teaser .teaser__pointers .pointer.pointer--1 .animate-pulse-refresh {
          animation-delay: 0s; }
      .header__teaser .teaser__pointers .pointer.pointer--2 {
        left: 80%;
        top: 70%; }
        .header__teaser .teaser__pointers .pointer.pointer--2 .animate-pulse-refresh {
          animation-delay: 2s; }
  .header__teaser.slide2 .layer-1, .header__teaser.slide3 .layer-1 {
    display: none; }
    @media screen and (max-width: 48.0625em) {
      .header__teaser.slide2 .layer-1, .header__teaser.slide3 .layer-1 {
        display: block; } }
  @media screen and (max-width: 64.0625em) {
    .header__teaser.slide2 .teaser__pointers, .header__teaser.slide3 .teaser__pointers {
      max-width: 100%;
      left: 0;
      margin-left: 0; } }
  .header__teaser .teaser__footer {
    position: absolute;
    bottom: 30%;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    pointer-events: none; }
    .header__teaser .teaser__footer a.button {
      pointer-events: auto; }
    .header__teaser .teaser__footer > div {
      position: relative; }
      @media screen and (max-width: 48.0625em) {
        .header__teaser .teaser__footer > div {
          position: static;
          flex: 0 0 100%;
          max-width: 100%;
          margin-left: 0; } }
    @media screen and (max-width: 48.0625em) {
      .header__teaser .teaser__footer {
        position: relative;
        margin: 0;
        pointer-events: auto; }
        .header__teaser .teaser__footer > .column, .header__teaser .teaser__footer > .columns {
          padding: 0; } }
  .header__teaser .teaser__title {
    color: #fff;
    z-index: 2; }
    .page--landing-400 .header__teaser .teaser__title {
      color: #4A556B; }
      .page--landing-400 .header__teaser .teaser__title .title {
        text-shadow: none !important; }
    .header__teaser .teaser__title.color-brand-secundary {
      color: #4A556B; }
      .header__teaser .teaser__title.color-brand-secundary .title {
        text-shadow: none !important; }
    @media screen and (max-width: 48.0625em) {
      .header__teaser .teaser__title {
        color: #4A556B; } }
    .header__teaser .teaser__title .title__label {
      font-size: 1.66667rem;
      line-height: 50px;
      padding: 0 14px;
      margin-bottom: 25px; }
      .header__teaser .teaser__title .title__label.bg-brand-secundary {
        background: #4A556B;
        color: #fff; }
      @media screen and (min-width: 64.0625em) {
        .header__teaser .teaser__title .title__label {
          max-height: 0px;
          overflow: hidden; }
          body.content--loaded .header__teaser .teaser__title .title__label.animated.is-animated {
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-name: slideHeight;
            animation-delay: .2s; } }
      @media screen and (max-width: 48.0625em) {
        .header__teaser .teaser__title .title__label {
          flex: 1 1 0px;
          padding-right: 1.11111rem;
          padding-left: 1.11111rem;
          min-width: 0;
          white-space: normal;
          margin-bottom: 0.55556rem; } }
  @media screen and (max-width: 48.0625em) and (min-width: 37.5em) {
    .header__teaser .teaser__title .title__label {
      padding-right: 1.38889rem;
      padding-left: 1.38889rem; } }
  @media screen and (max-width: 48.0625em) and (min-width: 48em) {
    .header__teaser .teaser__title .title__label {
      padding-right: 0.83333rem;
      padding-left: 0.83333rem; } }
    .header__teaser .teaser__title .title {
      text-shadow: 0 2px 30px rgba(0, 0, 0, 0.5); }
      @media screen and (max-width: 48.0625em) {
        .header__teaser .teaser__title .title {
          text-shadow: none;
          flex: 1 1 0px;
          padding-right: 1.11111rem;
          padding-left: 1.11111rem;
          min-width: 0; } }
  @media screen and (max-width: 48.0625em) and (min-width: 37.5em) {
    .header__teaser .teaser__title .title {
      padding-right: 1.38889rem;
      padding-left: 1.38889rem; } }
  @media screen and (max-width: 48.0625em) and (min-width: 48em) {
    .header__teaser .teaser__title .title {
      padding-right: 0.83333rem;
      padding-left: 0.83333rem; } }
      @media screen and (min-width: 64.0625em) {
        .header__teaser .teaser__title .title {
          filter: alpha(opacity=0);
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          -moz-opacity: 0;
          -khtml-opacity: 0;
          opacity: 0; }
          body.content--loaded .header__teaser .teaser__title .title.animated.is-animated {
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-name: fadeIn;
            animation-delay: .5s; } }
      .page--landing-400 .header__teaser .teaser__title .title h1, .page--landing-400 .header__teaser .teaser__title .title .h1 {
        font-weight: bold;
        margin-top: 20px; }
        @media screen and (min-width: 64.0625em) {
          .page--landing-400 .header__teaser .teaser__title .title h1, .page--landing-400 .header__teaser .teaser__title .title .h1 {
            font-size: 3.61111rem;
            margin-top: 0;
            line-height: 4.72222rem; } }
      .page--landing-400 .header__teaser .teaser__title .title h2, .page--landing-400 .header__teaser .teaser__title .title .h2 {
        font-weight: 500;
        font-size: 1.11111rem;
        line-height: 1.72222rem; }
        @media screen and (min-width: 64.0625em) {
          .page--landing-400 .header__teaser .teaser__title .title h2, .page--landing-400 .header__teaser .teaser__title .title .h2 {
            font-size: 2.77778rem;
            line-height: 3.38889rem;
            margin-bottom: 30px; } }

.header__navigation {
  position: relative;
  z-index: 3;
  margin-top: -10px;
  display: none; }
  @media screen and (max-width: 48.0625em) {
    .header__navigation {
      display: block; } }
  .header__navigation:after {
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    z-indeX: 0;
    height: 10px; }
  .header__navigation .navigation {
    position: relative;
    z-index: 1;
    background: #fff; }
    .header__navigation .navigation ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .header__navigation .navigation ul li a {
        display: block;
        border-top: 1px solid rgba(74, 85, 107, 0.05);
        padding: 20px 0;
        flex: 1 1 0px;
        padding-right: 1.11111rem;
        padding-left: 1.11111rem;
        min-width: 0;
        font-size: 1.11111rem;
        line-height: 1em;
        font-weight: 600;
        color: #FF6200; }
        @media print, screen and (min-width: 37.5em) {
          .header__navigation .navigation ul li a {
            padding-right: 1.38889rem;
            padding-left: 1.38889rem; } }
        @media print, screen and (min-width: 48em) {
          .header__navigation .navigation ul li a {
            padding-right: 0.83333rem;
            padding-left: 0.83333rem; } }
        .header__navigation .navigation ul li a:after {
          /* use !important to prevent issues with browser extensions that change fonts */
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e904";
          margin-left: 20px;
          vertical-align: middle; }
        .header__navigation .navigation ul li a:focus, .header__navigation .navigation ul li a:hover {
          color: #4A556B; }

.header__teaser-slider {
  background: #fff; }

@media screen and (min-width: 64em) {
  [data-slick-index="1"] .teaser__footer, [data-slick-index="2"] .teaser__footer {
    margin-bottom: -50px; } }

.spin__block {
  position: absolute;
  left: -300px;
  top: -310px;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .spin__block .spin .line {
    opacity: .4; }

.quote__block {
  display: inline-block;
  position: relative; }
  .quote__block .quote__name {
    margin-top: 20px; }
    .quote__block .quote__name .title {
      font-weight: 600; }

.quote__block--text-right {
  display: inline-block;
  position: relative; }
  .quote__block--text-right .quote__name {
    margin-top: 20px; }
    .quote__block--text-right .quote__name .title {
      font-weight: 600; }
  .quote__block--text-right .quote__slogan {
    font-family: "myriad-pro", sans-serif;
    font-size: 1.22222rem;
    line-height: 1.44444rem;
    font-weight: 700;
    margin: 1em 0;
    color: #FF6200; }
    .quote__block--text-right .quote__slogan .text-shadow {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 70px #fff, 0 0 80px #fff, 0 0 100px #fff, 0 0 150px #fff;
      color: #fff;
      display: none; }
    @media screen and (min-width: 64.0625em) {
      .quote__block--text-right .quote__slogan {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 90%;
        margin: 0;
        font-size: 1.77778rem;
        line-height: 2rem; }
        .quote__block--text-right .quote__slogan .text {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%; }
        .quote__block--text-right .quote__slogan .text-shadow {
          display: block; } }

.cta__block {
  margin-top: 30px; }
  @media print, screen and (min-width: 37.5em) {
    .cta__block {
      margin-top: 20px; } }
  @media screen and (min-width: 64.0625em) {
    .cta__block {
      margin-top: 60px; } }
  .cta__block .link, .cta__block .text__block a.link, .text__block .cta__block a.link, .cta__block .intro__block a.link, .intro__block .cta__block a.link {
    vertical-align: middle;
    margin-right: 15px;
    margin-bottom: 10px;
    display: inline-block; }
  .cta__block .button, .cta__block .text__block a.button, .text__block .cta__block a.button, .cta__block .intro__block a.button, .intro__block .cta__block a.button {
    vertical-align: middle;
    margin-bottom: 10px; }
  .cta__block .title {
    font-weight: bold;
    vertical-align: middle;
    margin-right: 15px;
    margin-bottom: 10px;
    display: inline-block; }

.image__block {
  position: relative; }
  .image__block.gymna400vip {
    position: absolute; }
    @media screen and (min-width: 37.5em) {
      .image__block.gymna400vip {
        bottom: -220px;
        left: 60%; } }
    @media screen and (max-width: 47.9375em) {
      .image__block.gymna400vip {
        bottom: -100px; } }
    @media screen and (max-width: 37.4375em) {
      .image__block.gymna400vip {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        bottom: -50px; } }
  .image__block .logo {
    float: right;
    margin-bottom: 50px; }
  .image__block .image__overlay {
    clear: both;
    max-width: 430px;
    width: 100%;
    display: block;
    float: left;
    position: relative;
    z-index: 0; }
    .image__block .image__overlay.right-bottom-top {
      margin-top: -55px;
      float: right;
      position: relative;
      z-index: 1; }
    .image__block .image__overlay.right-bottom-bottom {
      float: right;
      position: relative;
      z-index: 0; }
    .image__block .image__overlay.right-top-bottom {
      float: left;
      position: relative;
      z-index: 0; }
    .image__block .image__overlay.left-bottom-top {
      margin-top: -55px;
      float: left;
      position: relative;
      z-index: 1; }
    .image__block .image__overlay.left-top-top {
      margin-top: 55px;
      float: left;
      position: relative;
      top: 0;
      z-index: 1; }
      @media screen and (min-width: 64.0625em) {
        .image__block .image__overlay.left-top-top-large {
          margin-top: 55px;
          float: left;
          position: relative;
          top: 0;
          z-index: 1; } }
  @media screen and (min-width: 64.0625em) and (min-width: 64.0625em) {
    .image__block .image__overlay.left-top-top-large > a, .image__block .image__overlay.left-top-top-large > div {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      transform: translateY(-100%); } }
      .image__block .image__overlay.left-top-top > a, .image__block .image__overlay.left-top-top > div {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        transform: translateY(-100%); }
  .image__block + .help__block {
    margin-top: 35px; }

.form__block {
  color: #4A556B; }
  .form__block h2, .form__block .h2 {
    font-size: 1.66667rem;
    line-height: 1.94444rem;
    margin-bottom: 40px;
    max-width: 90%; }
  .form__block .block__overlay {
    background: #fff;
    padding: 40px; }
    @media screen and (max-width: 37.4375em) {
      .form__block .block__overlay {
        padding: 15px; } }
  .form__block input {
    margin-bottom: 15px; }
  .form__block textarea {
    height: 100px;
    resize: none;
    margin-bottom: 15px; }
  .form__block button {
    margin-top: 23px;
    margin-bottom: 0; }

.help__block {
  font-size: 0.77778rem;
  line-height: 1.22222rem;
  color: rgba(74, 85, 107, 0.5); }

.page--landing-400 .usp__block h3, .page--landing-400 .usp__block .h3 {
  color: #FF6200; }

.usp__block ul {
  margin-left: 0; }
  .usp__block ul li {
    list-style: none;
    position: relative;
    padding-left: 23px;
    margin-bottom: 20px; }
    .usp__block ul li:before {
      content: "";
      margin-right: 8px;
      background: #FF6200;
      border-radius: 100%;
      height: 14px;
      width: 14px;
      line-height: 14px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      left: 0;
      top: 8px; }

.chart__block {
  margin-top: 20px;
  display: none; }
  @media screen and (min-width: 64.0625em) {
    .chart__block {
      display: block; } }
  .chart__block .chart__item {
    display: inline-block;
    margin: 0 25px 25px 0; }
    .chart__block .chart__item:last-child {
      margin-right: 0; }
  .chart__block.text-center .chart__item {
    margin-bottom: 0; }

.specifications__block ul {
  list-style: none;
  margin: 0;
  padding: 0; }
  .specifications__block ul li {
    position: relative;
    padding-left: 30px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 15px;
    font-size: 1.11111rem;
    line-height: 1.33333rem;
    white-space: nowrap; }
    .specifications__block ul li a {
      color: #4A556B;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }
      .specifications__block ul li a:before {
        height: 18px;
        width: 18px;
        border: solid #FF6200 2px;
        border-radius: 100%;
        content: "";
        display: inline-block;
        position: absolute;
        left: 0;
        top: 5px;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out; }
      .specifications__block ul li a:focus {
        color: inherit; }
      .specifications__block ul li a.is-active, .specifications__block ul li a:hover {
        color: #FF6200; }
        .specifications__block ul li a.is-active:before, .specifications__block ul li a:hover:before {
          background: #FF6200; }

.text__block ol, .intro__block ol {
  margin-left: 0;
  margin-top: 45px; }
  .text__block ol li, .intro__block ol li {
    counter-increment: step-counter;
    list-style: none;
    position: relative;
    padding-left: 37px; }
    .text__block ol li:before, .intro__block ol li:before {
      content: counter(step-counter);
      margin-right: 5px;
      color: #fff;
      font-weight: bold;
      background: #FF6200;
      border-radius: 100%;
      height: 23px;
      width: 23px;
      font-size: 0.88889rem;
      line-height: 23px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      position: absolute;
      left: 0;
      top: 0; }
    .text__block ol li p, .intro__block ol li p {
      font-size: 0.88889rem;
      line-height: 1.22222rem; }

.text__block a, .intro__block a {
  color: #FF6200; }
  .text__block a:focus, .intro__block a:focus, .text__block a:hover, .intro__block a:hover {
    color: #d95300; }

.controls__block {
  margin-bottom: 40px; }
  .controls__block .title {
    font-weight: 700;
    color: #FF6200;
    margin-bottom: 20px; }
  .controls__block .label, .controls__block .header__teaser .teaser__title .title__label, .header__teaser .teaser__title .controls__block .title__label {
    border-radius: 26px;
    color: #fff;
    background-color: #CFCFCF;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.33333rem;
    padding: 5px 22px;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin-right: 10px;
    cursor: pointer;
    position: relative; }
    .controls__block .label.is-active, .controls__block .header__teaser .teaser__title .is-active.title__label, .header__teaser .teaser__title .controls__block .is-active.title__label, .controls__block .label:hover, .controls__block .header__teaser .teaser__title .title__label:hover, .header__teaser .teaser__title .controls__block .title__label:hover, .controls__block .label:focus, .controls__block .header__teaser .teaser__title .title__label:focus, .header__teaser .teaser__title .controls__block .title__label:focus {
      background: #FF6200;
      color: #fff; }
    .controls__block .label.is-active:after, .controls__block .header__teaser .teaser__title .is-active.title__label:after, .header__teaser .teaser__title .controls__block .is-active.title__label:after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #FF6200; }
    .controls__block .label.is-active:focus, .controls__block .header__teaser .teaser__title .is-active.title__label:focus, .header__teaser .teaser__title .controls__block .is-active.title__label:focus, .controls__block .label.is-active:hover, .controls__block .header__teaser .teaser__title .is-active.title__label:hover, .header__teaser .teaser__title .controls__block .is-active.title__label:hover {
      background: #d95300; }
      .controls__block .label.is-active:focus:after, .controls__block .header__teaser .teaser__title .is-active.title__label:focus:after, .header__teaser .teaser__title .controls__block .is-active.title__label:focus:after, .controls__block .label.is-active:hover:after, .controls__block .header__teaser .teaser__title .is-active.title__label:hover:after, .header__teaser .teaser__title .controls__block .is-active.title__label:hover:after {
        border-top-color: #d95300; }

.intro__block p {
  font-size: 1.11111rem;
  line-height: 1.44444rem; }

.icon__block {
  text-align: center;
  margin-bottom: 20px; }
  .icon__block .icon {
    display: inline-block;
    color: #fff;
    margin-bottom: 30px;
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px; }
    .icon__block .icon:before {
      content: "\e908";
      color: #994d9d; }
  .icon__block .logo {
    margin-bottom: 20px;
    display: inline-block; }
  .icon__block .icon__text {
    font-size: 1.11111rem;
    line-height: 1.44444rem; }
    .icon__block .icon__text a {
      color: #FF6200; }
      .icon__block .icon__text a:hover {
        text-decoration: underline; }
  .icon__block .icon__line {
    height: 1px;
    width: 34px;
    margin-top: 30px;
    background-color: #FF6200;
    margin: 0 auto; }
  .icon__block ul {
    list-style: none;
    font-size: 1rem; }
    .icon__block ul li {
      margin-bottom: 5px; }
      .icon__block ul li span {
        background: #FF6200;
        color: #fff;
        display: inline;
        padding: 0 5px; }
  .icon__block h4 {
    font-weight: bold;
    font-size: 1.11111rem;
    line-height: 1.44444rem;
    margin-bottom: 10px; }

@media print, screen and (min-width: 64.0625em) {
  .section__text--text-left .text__block, .section__text--text-left .intro__block {
    padding-right: 115px; } }

.section__text--text-center {
  text-align: center; }

@media print, screen and (min-width: 64.0625em) {
  .section__text--text-right .text__block, .section__text--text-right .intro__block {
    padding-left: 115px; } }

.section__productoverview .productoverview__title {
  margin-bottom: 60px; }
  .section__productoverview .productoverview__title h2 {
    margin-bottom: 0;
    color: #4A556B; }
  .section__productoverview .productoverview__title .link {
    margin-top: 10px;
    display: inline-block; }

.section__productoverview .link, .section__productoverview .text__block a.link, .text__block .section__productoverview a.link, .section__productoverview .intro__block a.link, .intro__block .section__productoverview a.link {
  color: #FF6200;
  text-decoration: underline;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  .section__productoverview .link:hover {
    text-decoration: none; }

@media screen and (max-width: 64em) {
  .section__productoverview .horizontal-divider {
    position: relative;
    height: 1px;
    bottom: 0;
    width: 100%;
    border-bottom: 1px dashed #B7B7B7;
    margin-bottom: 50px;
    margin-top: 50px; } }

.section__productoverview .vertical-divider {
  position: relative;
  height: 1px;
  bottom: 0;
  width: 100%;
  border-bottom: 1px dashed #B7B7B7;
  margin-bottom: 50px; }
  @media screen and (min-width: 48em) {
    .section__productoverview .vertical-divider {
      position: absolute;
      height: 75%;
      width: auto;
      right: 0;
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
      border-bottom: none;
      margin-bottom: 0; }
      .page--landing-400 .section__productoverview .vertical-divider {
        height: 100%; } }

.section__easytouse img {
  margin-left: auto;
  margin-right: auto;
  display: block; }

.section__expertise .text__block, .section__expertise .intro__block {
  position: relative;
  z-index: 1; }

.section__expertise .section-padding {
  padding-top: 40px; }

.section__expertise img {
  display: block; }
  @media screen and (max-width: 37.4375em) {
    .section__expertise img {
      margin: 0 auto; } }

.section__contactform {
  min-height: 900px; }
  @media screen and (max-width: 47.9375em) {
    .section__contactform {
      min-height: 300px; } }
  @media print, screen and (min-width: 64.0625em) {
    .section__contactform .align-center {
      justify-content: left; } }
  @media screen and (max-width: 37.4375em) {
    .section__contactform.section-padding {
      padding: 20px 0; } }

footer {
  font-size: 0.88889rem;
  line-height: 1.05556rem;
  color: rgba(74, 85, 107, 0.3); }
  footer a {
    color: rgba(74, 85, 107, 0.3); }
    footer a:focus, footer a:hover {
      color: rgba(11, 13, 17, 0.3); }
  footer .footer__inner {
    padding: 65px 0; }
  footer .logo {
    display: inline-block;
    margin-bottom: 10px; }
    footer .logo img {
      height: 36px;
      width: 130px;
      display: block; }
  footer .copyright {
    display: block; }
    @media screen and (max-width: 90.0625em) {
      footer .copyright {
        margin-bottom: 30px; } }
  footer .navigation {
    list-style: none;
    margin: 0;
    padding: 0; }
    @media screen and (min-width: 37.5em) {
      footer .navigation {
        margin-top: 12px; } }
    footer .navigation li {
      padding: 0;
      margin: 0; }
