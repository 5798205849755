* {
  outline:none;
}

html {
  min-height: 100%;
  width: 100%;
}
body {
  background: $color-white;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  @include transition(all,.2s);

  .hamburger-close {
    display:none;
  }
  
}

.js {
  body {

    // page loading
    &.page--loading {

      &:after {
        content:"";
        position:fixed;
        background:$color-white;
        height:100%;
        width:100%;
        z-index:999999;
        top:0;
        left:0;
        @include transition(all,.2s);
      }

      &.page--loaded {
        &:after {
          @include opacity(0);
        }
      }

    }

  }
  
}

// Margins
// -----------------
.margin-bottom {
  margin-bottom:40px;
}

// Parallax
// -----------------

// Parallax
[data-paroller-factor],.paroller {
  @include breakpoint(769 down) {
    transform:none !important;
  }
}


// Horizontal divider
// -----------------

.horizontal-divider {
  border-bottom: 1px dashed #B7B7B7;
  width:100%;
}


// Vertical divider
// -----------------

.vertical-divider {
  border-right: 1px dashed #B7B7B7;
  height:100%;
}

.mpf-video {
  position:relative;
  
  img {
    @include transition(all,.5s);
  }

  &:after {
    content:"";
    position:absolute;
    height:100%;
    width:100%;
    background:rgba($brand-secundary,0.2);
    z-index:1;
    left:0;
    top:0;
    @include transition(all,.5s);
  }
  &:before {
    height:68px;
    width:68px;
    @include border-radius(100%);
    background:rgba($color-white,.5);
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    z-index:2;
    line-height:68px;
    text-align:center;
    color:#fff;
    font-size:20px;
  }
  
  @extend .icon-triangle;
  
  &:hover {
    &:after {
      @include transform(scale(1.05));
    }
    img {
      @include transform(scale(1.05));
    }
    
  }
  
}


// Colors
// -----------------
.color-brand-primary {
  color: $brand-primary;
}

.color-brand-secundary {
  color: $brand-secundary;
}

.highlight {
  color:$brand-primary;
}

// Backgrounds
// -----------------

.bg-image {
  background-position: center center;
  background-size:cover;
  background-repeat:no-repeat;
  
  &.greyoval {
    background-image:url('../images/gymna-400/background-greyoval-gts.png');
    background-size: contain !important;
    background-position: -50px 10% !important;

    @include breakpoint(large up){
      background-position: 80% 10% !important;

    }
    
    @include breakpoint(small down){
      display:none;
    }
  }

  &.gymna400vip {
    background-image:url('../images/gymna-400/background-splash-gymna400vip.png');
    background-size: contain !important;

    background-position: 20px 30px !important;
    background-size: 850px !important;
    
    @include breakpoint(large up){
      background-position: 20px 30px !important;
      background-size: 850px !important;
    }

    @include breakpoint(small down){
      //display:none;
    }
  }


  &.splash {
    background-image:url('../images/gymna-400/background-splash-easy-to-use.png');
    background-size: contain !important;

    background-position: top right !important;
    background-size: 850px !important;

    @include breakpoint(large up){
      background-position: 20px right !important;
      background-size: 850px !important;
    }

    @include breakpoint(small down){
      //display:none;
    }
  }
  
  
}

.column-bg-image-left {

  .bg-image {
      position:absolute;
      top:0;
      left:0;
      z-index:0;
      height:100%;
      width:50%;
      background-repeat:no-repeat;
      background-size:cover;
      background-position: center center;
  }

  @include breakpoint(small down){
      position:relative;
      //padding-top:40px;
      padding-bottom:40px;
      margin-top:20px;
      .bg-image {
        width:100%;
      }
  }

}

.column-bg-image-right {

  .bg-image {
      position:absolute;
      top:0;
      right:0;
      z-index:0;
      height:100%;
      width:50%;
      background-repeat:no-repeat;
      background-size:cover;
      background-position: center center;
  }

  @include breakpoint(small down){
      position:relative;
      //padding-top:40px;
      padding-bottom:40px;
      margin-top:20px;
      .bg-image {
        width:100%;
      }
  }

}

.bg-grey {
  background: $brand-grey-light;
}

.bg--shadow-bottom {
  box-shadow: 0 -7px 164px 0 rgba($black,0.05);
}

.bg--shadow-top {
  box-shadow: 0 13px 164px 0 rgba($black,0.1);
}

.bg-brand-primary {
  background: $brand-primary;
  color:$color-white;
}

.bg-brand-secundary {
  background: $brand-secundary;
  color:$color-white;
}

.bg-white {
  background:$color-white;

  @include breakpoint(medium down){
    &-medium {
      background:$color-white;
    }
  }
  
  @include breakpoint(small down){
    &-small {
      background:$color-white;
    }
  }
  
}


.wow {
  visibility: hidden;
/*
  @include breakpoint(medium down){
    visibility: visible !important;
    animation: none !important;
    transform: none !important;
    transition: none !important;
    animation-name: none !important;
    animation-duration: 0s !important;
    animation-fill-mode: both  !important;
    animation-delay: 0s !important;
    
  }
  */
}


// Buttons and links
// -----------------

.clickable {
  cursor: pointer;
}

.link {
  font-weight:600;
  color:$button-background;
  
  &:focus,
  &:hover {
    color:$button-background-hover;
  }
  
}

.button {
  @include border-radius(26px);
  @include transition(all,.2s);
  font-family: $font-main;
  font-weight:600;
  vertical-align: middle;
  font-size: rem-calc(18px);
  padding:15px 27px;
  margin: 0 0 1rem 0;
  background:$button-background;
  color:$color-white;

  @include breakpoint(small down){
    font-weight:500;
    padding:10px 15px;
    font-size: rem-calc(15px);
  }
  
  + .link {
    margin: 0 0 1rem 30px; 
    display:inline-block;
    vertical-align: middle;
  }

  &:focus,
  &:hover {
    background:$button-background-hover;
    color:$color-white;
  }
  
  &.outline {
    border:1px solid $color-white;
    background-color: $brand-secundary;
    &:focus,
    &:hover {
      background-color: $brand-primary;
      border:1px solid $brand-primary;
    }
  }

  &.reverse {
    background-color: $brand-primary;
    &:focus,
    &:hover {
      background-color: $brand-secundary;
    }

    &.outline {
      border:1px solid transparent;

      &:focus,
      &:hover {
        border:1px solid #fff;
      }
    }

  }

  &.brand-grey {
    background:$button-grey;
    color:$color-white;
    font-size: rem-calc(18px);
    @include border-radius(25px);
    &:focus,
    &:hover {
      background:$button-grey-hover;
    }
  }
  
  
  &.large {
    font-size: rem-calc(20px);
    padding:15px 27px;
    @include border-radius(26px);

    @include breakpoint(small down){
      font-weight:600;
      vertical-align: middle;
      font-size: rem-calc(18px);
      padding:15px 27px;
    }
    
  }
  
}


a {
  color: #2199e8;
  text-decoration: none;
  line-height: inherit;
  cursor: pointer;
}

p {
  margin-bottom:30px;
}

// Spin
// -----------------

@keyframes spinIn {
  from {
    opacity: 0;
  }

  to {
    opacity: .4;
  }
}

@keyframes spinScale {
  from {}
  to {
    transform:scale(2);
  }
}

.spin {

  position:absolute;
  z-index:1;
  width:845px + 500px;
  height:764px + 500px;
  
  .line {
    opacity: 0;
  }
  


  .slide2 & {
    bottom: -340px;
    right: -210px;
  }

  .slide3 & {
    bottom: -340px;
    left: -530px;
  }


  &.is-visible {
    .line {
      opacity: .4;  
    }
  }

  &.is-animated {

      animation-duration: 5s;
      animation-fill-mode: both;
      animation-name: spinScale;

    .line {
        animation-duration: .5s;
        animation-fill-mode: both;
        animation-name: spinIn;
    }
    
  } 

  .line {
    position:absolute;
    left:50%;
    top:50%;
    transform:translateY(-50%) translateX(-50%);
    display:block;
    background: url('../images/spin.svg') no-repeat center center;
    background-size:100%;
    opacity:0;

    &.line--1 {
      width:100%;
      height:100%;
      animation-duration: .30s;
    }

    &.line--2 {
      width:90%;
      height:90%;
      animation-duration: .25s;
    }

    &.line--3 {
      width:75%;
      height:75%;
      animation-duration: .20s;
    }

    &.line--4 {
      width:60%;
      height:60%;
      animation-duration: .15s;
    }

    &.line--5 {
      width:55%;
      height:55%;
      animation-duration: .1s;
    }

    &.line--6 {
      width:50%;
      height:50%;
      animation-duration: .095s;
    }

    &.line--7 {
      width:45%;
      height:45%;
      animation-duration: .085s;
    }

    &.line--8 {
      width:40%;
      height:40%;
      animation-duration: .075s;
    }

    &.line--9 {
      width:30%;
      height:30%;
      animation-duration: .065s;
    }

    &.line--10 {
      width:25%;
      height:25%;
      animation-duration: .055s;
    }

    &.line--11 {
      width:20%;
      height:20%;
      animation-duration: .045s;
    }

    &.line--12 {
      width:15%;
      height:15%;
      animation-duration: .035s;
    }

    &.line--13 {
      width:12%;
      height:12%;
      animation-duration: .025s;
    }

    &.line--14 {
      width:10%;
      height:10%;
      animation-duration: .015s;
    }

  }


}

// Positions
// -----------------

.position-relative {
  position:relative;
}

.position-absolute {
  position:absolute;
}

.z-index-1 {
  z-index:1;
}


.z-index-2 {
  z-index:2;
}

// Focuspoint
// -----------------
.focuspoint {
  position: absolute; /*Any position but static should work*/
  overflow: hidden;
  height:100%;
  width:100%;

  img {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    display: block;
    /* fill and maintain aspect ratio */
    width: auto; height: auto;
    min-width: 100%; min-height: 100%;
    max-height: none; max-width: none;
  }


}

// Parallax
// -----------------

.dummy {
  visibility: hidden;
  position:relative;
  z-index: 0;
}


.parallax {

  position: absolute;
  height: 100%;
  width: 100%;
  top:0;
  
  @keyframes explosion {
    20% {
      transform: scale3d(0.9, 0.9, 0.9) translateY(10%);
    }

    50%,
    55% {
      opacity: 1;
      transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
      opacity: 0;
      transform: scale3d(20, 20, 20);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .explosion {
    animation-name: explosion;
    animation-duration: 0.75s;
    animation-fill-mode: both;
  }

  .dummy {
    position: relative;
    z-index:0;
  }

  .layer-1, .layer-2, .layer-3 {
    position:absolute;
    z-index:0;
    left:-100px;
    right: -100px;
    top:-100px;
    bottom:-100px;
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center bottom;

    @include breakpoint(1025 down){
      bottom:0 !important;
      transform:none !important;
    }

    &.loading {
      transition: transform 1s ease-in-out;
    }
    
    
    img.is-hide {
      opacity:0;

      &.fadeIn {
          animation-name: fadeIn;
          animation-duration: 0.75s;
          animation-fill-mode: both;
      }

    }

  }
  
  .parallax__image {
    //width:calc(100% + 90px) !important;
    @include breakpoint(large up){
      //width:calc(100% + 150px) !important;
    }
  }

  .layer-1 {
    z-index:1;
  }

  .layer-2 {
    z-index:2;

    img {
      position: absolute;
      bottom:0;
    }
    
    .page--landing-400 & {
      img {
        position: absolute;
        right: -15%;
        bottom:auto;
      }
    }

    .slide3 & {
      img {
        position: absolute;
        left:100px;
        bottom:0;
        max-width:40%;
      }
    }
    
    .slide2 & {
      img {
        position: absolute;
        right: 100px;
        bottom:0;
        max-width:40%;
      }
    }
    
  }
  
  .layer-3 {
    z-index:3;

    img {
      position: absolute;
      bottom:0;
    }
    
    .page--landing-400 & {
      img {
        position: absolute;
        right: 100px;
        transform: translateY(-50%);
        top: 50%;
        margin-top: 50px;
        bottom:auto;
      }
    }
    
  }



}

// Popups
// -----------------
.popup {
  position: relative;
  background: #FFF;
  padding: 75px 65px;
  width: auto;
  max-width: 590px;
  margin: 20px auto;
  box-shadow: 0 22px 53px 0 rgba(0,0,0,0.1);
  
  p {
    font-size: rem-calc(18px);
    line-height: rem-calc(26px);
  }
  
  .mfp-close {
    top:22px;
    right:22px;
    font-size:38px;
  }
  
}



// Site wrapper
// -----------------


#site_wrapper {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed;
  overflow: hidden;
  max-width: 100%;
  position: relative;
}

#main {
  height: auto;
}

header, #main, footer {
  display: table-row;
  height: 1px;
  width: 100%;
  overflow: hidden;
}

header {
  position:relative;
  z-index:0;
}

// Labels
// -----------------
.label {
  background:$brand-primary;
  color:$color-white;
  b {
    font-weight:600;
  }
}

// Shadow
// -----------------

.shadow {
  box-shadow: -10px 10px 56px 0 rgba(0,0,0,0.15);
}

// Bordered
// -----------------
.bordered {
  border:6px solid #fff;
}

// Paddings and Margins
// -----------------
.section-padding {
  padding-top:40px;

  &.bg-image,
  &.bg--shadow-bottom,
  &.bg--shadow-top {
    padding-bottom:40px;
  }

  @include breakpoint(medium){
    padding-top:80px;

    &.bg-image,
    &.bg--shadow-bottom,
    &.bg--shadow-top {
      padding-bottom:80px;
    }
  }
  
  @include breakpoint(large){
      padding-top:125px;

      .page--landing-400 & {
        padding-top:80px;
      }
    
      &.bg-image,
      &.bg--shadow-bottom,
      &.bg--shadow-top {
        padding-bottom:125px;

        .page--landing-400 & {
          padding-bottom:80px;
        }
        
      }
    
  }
  
  + .bg--shadow-top {
    margin-top:40px;
    @include breakpoint(medium){
      margin-top:80px;
    }
    @include breakpoint(large){
      margin-top:125px;
    }
  }

  @include breakpoint(small down) {
    &.gymna400vip {
      padding-bottom: 0 !important;
    }
  }
}

.no-gutter {
  margin-left:0;
  margin-right:0;

  > .column,
  > .columns {
    padding-left:0;
    padding-right:0;
  }

}


// Context: Upgrade your practice
// -----------------

.context__practice {
  background:#fff;
  position:relative;
  z-index:1;
}


// Context: Treatment
// -----------------
.context__treatments {
  .image__block {
    .image__overlay {
      float:right;
      margin-top:-55px;
      width:auto;
      margin-right:20px;
    }
  }
  .help__block {
    margin:10px 0 0 0;
  }
}

.form__message {
  .error {
    color: get-color(alert);
  }
  .success {
    color:get-color(success);
  }
}

.button__expertise {
  width:100px;
  margin-bottom: 30px;
  display:block;
  position:relative;
  z-index:2;
}

.rasterlines {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 0px;
  transform: translateX(-50%);
}




