// Section text: text-left
// -----------------
.section__text--text-left {
  .text__block {
    @include breakpoint(large){
      padding-right:115px;
    }
  }
}

// Section text: text-center
// -----------------
.section__text--text-center {
  text-align:center;
}

// Section icons
// -----------------
.section__icons {
}


// Section text: text-right
// -----------------
.section__text--text-right {
  .text__block {
    @include breakpoint(large){
      padding-left:115px;
    }
  }
}

// Section productoverview
// -----------------
.section__productoverview {
  
  .productoverview__title {
    
    margin-bottom:60px;

    h2 {
      margin-bottom:0;
      color:$brand-secundary;
    }
    
    .link {
      margin-top:10px;
      display:inline-block;
    }
    
  }
  
  .link {
    color:$brand-primary;
    text-decoration: underline;
    @include transition(all, .2s);
    &:hover {
      text-decoration: none;
    }
  }
  
  .horizontal-divider {
    @include breakpoint(landscapebr down){
      position:relative;
      height:1px;
      bottom:0;
      width:100%;
      border-bottom: 1px dashed #B7B7B7;
      margin-bottom:50px;
      margin-top::50px
    }
  }
  
  
  .vertical-divider {
    position:relative;
    height:1px;
    bottom:0;
    width:100%;
    border-bottom: 1px dashed #B7B7B7;
    margin-bottom:50px;

    @include breakpoint(landscapebr up){
      position:absolute;
      height:75%;
      width:auto;
      right:0;
      top:50%;
      bottom:auto;
      transform:translateY(-50%);
      border-bottom:none;
      margin-bottom:0;
      
      .page--landing-400 & {
        height:100%;
      }
      
    }
  }
}

.section__easytouse {
  img {
    margin-left:auto;
    margin-right:auto;
    display: block;
  }
}

.section__expertise {
  
  .text__block {
    position:relative;
    z-index:1;
  }
  
  .section-padding {
    padding-top: 40px;
  }
  
  img {
    @include breakpoint(small down) {
      margin: 0 auto;
    }
    display: block;
  }
  
}

// Section contactform
// -----------------
.section__contactform {
  
  min-height: 900px;
  
  @include breakpoint(medium down){
    min-height: 300px;
  }
  
  .align-center {
    @include breakpoint(large){
        justify-content: left;
    }
  }
  &.section-padding {
    @include breakpoint(small down){
      padding:20px 0;
    }
  }
  
}