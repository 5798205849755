
// Ticker
// -----------------

.ticker {
  line-height:35px;
  box-shadow: 0 2px 14px 0 rgba(0,0,0,0.05);
  background:$color-white;
  @include border-radius(26px);
  padding:0 17px;
  color:$brand-secundary;
  font-size: rem-calc(14px);
  display:inline-block;
  vertical-align: middle;
  display: none !important;

  a {
    color:inherit;
  }

  .ticker__repeat {
    display:inline-block;
    vertical-align: middle;
  }
  .ticker__message {
    display:inline-block;
    vertical-align: middle;
  }

  .icon-refresh {
    font-size: rem-calc(10px);
    display:inline-block;
    vertical-align: middle;
    margin-right:10px;
  }

  .icon-help {
    font-size: rem-calc(15px);
    display:inline-block;
    vertical-align: middle;
    margin-left:35px;
  }

  @include breakpoint(815 down){
    display: none;
  }


}