// Fonts
// ===========================
$font-size: rem-calc(18px);

$font-main: "myriad-pro", sans-serif;
$font-header: "myriad-pro", sans-serif;

// Colors
// ===========================
$color-white: #fff;
$color-grey-light: #F6F6F6;

$button-color: #fff;

$brand-primary: #FF6200;
$brand-secundary: #4A556B;
$brand-grey-light: rgba(#4A556B,0.3);
$brand-text-color: #4A556B;

$button-background: #FF6200;
$button-background-hover: scale-color($button-background, $lightness: -15%);

$button-grey: rgba(#4A556B,0.2);
$button-grey-hover: darken($button-grey,75%);

// Form
// ===========================
$form-radius: 20px;
$form-placeholder: rgba($brand-text-color,.5);
$form-text-color: $brand-text-color;
$form-border: 1px solid rgba(74,85,107,0.11);
$form-padding:rem-calc(10px) rem-calc(20px);
$form-lineheight:rem-calc(22px);

